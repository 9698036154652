import { Col, Image, Row, Typography } from "antd";
import React from "react";
import styles from "../Styles/horizontalcard2.module.css";
import { Link } from "react-router-dom";
const HorizontalCard2 = ({ image, title, date, path, id }) => {
  return (
    <>
      <Link to={`/${id}`}>
        <Row gutter={[8, 8]} className={styles.card}>
          <Col xs={6} sm={6} md={6} lg={10}>
            <Image
              width={"100%"}
              height={"90px"}
              style={{ objectFit: "cover" }}
              src={image}
              alt="TagImage"
              preview={false}
              className={styles.image}
            />
          </Col>
          <Col xs={18} sm={18} md={18} lg={14}>
            <Typography.Paragraph
              className={styles.title}
              ellipsis={{ rows: 4 }}
            >
              {title}
            </Typography.Paragraph>
          </Col>
        </Row>
      </Link>
    </>
  );
};

export default HorizontalCard2;
