import React, { useEffect, useState } from "react";
import {
  Carousel,
  Col,
  Divider,
  Empty,
  Image,
  Layout,
  Pagination,
  Row,
  Typography,
} from "antd";
import { v4 as uuid4 } from "uuid";
import womenLeadersBanner from "../Assets/womenLeaders.jpg";
import HorizontalCard from "../Common/Components/HorizontalCard";
import Link from "antd/es/typography/Link";
import { useDispatch, useSelector } from "react-redux";
import { magazineAction } from "../Magazines/state/actions";
import { fallback } from "../Common/utils/faultTolerant";
import { upTrendingAction } from "../UpTrending/state/actions";
import { womenLeaderAndCoverStoriesAction } from "./state/actions";
import Spinner from "../Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import HorizontalCard2 from "../Common/Components/HorizontalCard2";
import styles from "./Styles/womenLeaders.module.css";
import { Helmet } from "react-helmet-async";
import { constants } from "../Common/Components/Constants";
const { Title, Text } = Typography;
const { Content } = Layout;

const WomenLeaders = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let { magazineData } = useSelector((state) => state.magazineReducer);
  let { newEntry } = useSelector((state) => state.upTrendingReducer);
  newEntry = newEntry?.slice(0, 6);
  let { size, womenLeadersAndCoverStoriesData } = useSelector(
    (state) => state.womenLeadersAndCoverStoriesReducer
  );
  const { isLoading } = useSelector((state) => state.loadingReducer);

  useEffect(() => {
    dispatch(magazineAction.getMagazineModalData("user", 1));
    dispatch(upTrendingAction.getUpTrendingData("user", 1));
    dispatch(womenLeaderAndCoverStoriesAction.getWomenLeadersModalData(1));
  }, [dispatch]);

  const [current, setCurrent] = useState(1);
  const onChange = (page) => {
    setCurrent(page);
    dispatch(womenLeaderAndCoverStoriesAction.getWomenLeadersModalData(page));
  };

  if (isLoading) {
    return <Spinner />;
  }

  let handleRedirect = (id) => {
    navigate(`/${id}`);
  };

  return (
    <Content style={{ overflowX: "hidden" }}>
      <Helmet>
        <meta name="keywords" content={constants.womenLeaders.title}></meta>
        <title>{constants.womenLeaders.title}</title>
        <meta name="description" content={constants.womenLeaders.description} />

        <meta name="twitter:card" content="type" />
        <meta name="twitter:title" content={constants.womenLeaders.title} />
        <meta name="twitter:description" content={constants.womenLeaders.description} />

        <meta property="og:title" content={constants.womenLeaders.title} />
        <meta property="og:description" content={constants.womenLeaders.description} />
        <meta property="og:type" content="website" />
        <meta property="og:URL" content={`https://visionaryvogues.com/women-leaders`} />
      </Helmet>
      <section>
        <img
          src={womenLeadersBanner}
          alt="Women_leaders"
          className={styles.image}
        />
        <div className={styles.overlay}>
          <Title
            style={{ display: "block", marginBottom: "0" }}
            className={styles.title}
          >
            women leaders
          </Title>
          <Text style={{ display: "block" }} className={styles.text}>
            Empowering insights for women leaders navigating challenges &
            fostering success in today's world.
          </Text>

          <div className={styles.buttonWrapper}>
            <button type="submit" onClick={() => navigate("/magazines")}>
              View Magazines
            </button>
          </div>
        </div>
      </section>

      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
        className={styles.listingRow}
        style={{ overflowX: "hidden", justifyContent: "center" }}
      >
        <Col xs={24} sm={24} lg={16} xl={16} xxl={18}>
          <Row gutter={[16, 16]}>
            {womenLeadersAndCoverStoriesData?.length === 0 ||
              womenLeadersAndCoverStoriesData === undefined ? (
              <Empty
                style={{
                  marginTop: "100px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            ) : (
              womenLeadersAndCoverStoriesData?.map((data) => (
                <Col
                  xs={24}
                  sm={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  key={uuid4()}
                  style={{ paddingLeft: "10px" }}
                >
                  <div onClick={() => handleRedirect(data?.redirectURL)}>
                    <HorizontalCard
                      image={data?.poster}
                      title={data?.title}
                      description={data?.description}
                      coverstories={true}
                      path={"dailyScoop"}
                      tag={
                        data?.tag
                          ? data?.tag[0].toUpperCase() + data?.tag?.slice(1)
                          : "Magazine"
                      }
                      id={data?.redirectURL}
                      textType={5}
                    />
                  </div>
                  <Divider className={styles.divider} />
                </Col>
              ))
            )}
          </Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: "30px",
              }}
            >
              <Pagination
                current={current}
                onChange={onChange}
                defaultCurrent={1}
                pageSize={4}
                hideOnSinglePage={true}
                total={size}
                showSizeChanger={false}
              />
            </div>
          </Col>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={4}>
          <Row gutter={[0, 16]} justify={"center"}>
            <Col xs={12} sm={12} md={8} lg={24} xl={24} xxl={24}>
              <Carousel
                autoplay
                effect="scrollx"
                style={{ backgroundColor: "#00569E", border: "none" }}
                dots={false}
              >
                {magazineData?.length === 0 || magazineData === undefined ? (
                  <Empty
                    style={{
                      marginTop: "100px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                ) : (
                  magazineData?.map((data) => (
                    <Image
                      src={data?.thumbnail}
                      alt="image5"
                      width={"100%"}
                      height={"100%"}
                      preview={false}
                      fallback={fallback}
                      onClick={() =>
                        navigate(`/magazine/${data?.redirectURL}`)
                      }
                    />
                  ))
                )}
              </Carousel>
            </Col>
            {newEntry?.length === 0 || newEntry === undefined ? (
              <Empty
                style={{ marginTop: "100px" }}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            ) : (
              <Row className={styles.sideSection}>
                {newEntry?.map((data) => (
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    key={uuid4()}
                  >
                    <HorizontalCard2
                      image={data?.thumbnail}
                      title={data?.title}
                      path={"upTrending"}
                      id={data?.redirectURL}
                      textType={2}
                    />
                  </Col>
                ))}
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <div className={styles.viewMagazineBtnWrapper}>
                    <Link to={"/up-trending"}>
                      <button
                        type="submit"
                        onClick={() => navigate("/up-trending")}
                      >
                        View more
                      </button>
                    </Link>
                  </div>
                </Col>
              </Row>
            )}
          </Row>
        </Col>
      </Row>
    </Content>
  );
};

export { WomenLeaders };
