import { commonConstants } from "./action-types";

const initialState = {
    article: false,
    articleError: false,
};

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case commonConstants.GET_ARTICLE_BY_REDIRECT_URL_SUCCESS:
            return {
                ...state,
                article: action.blogs,
                articleError: false,
            };
        case commonConstants.GET_ARTICLE_BY_REDIRECT_URL_FAILURE:
            return {
                ...state,
                article: false,
                articleError: action.newEntryError,
            };
        case commonConstants.CLEAR_ARTICLE:
            return {
                ...state,
                article: false,
                articleError: false,
            }
        default:
            return state;
    }
};
export { commonReducer };
