import { dailyScoopConstants } from "./action-types";

const initialState = {
  newEntry: [],
  newEntryDescription: [],
  newEntryLoading: true,
  newEntryError: null,
  newDailyScoopEntries: [],
  totalCount: 0,
  totalCountTag: 0,
};

const dailyScoopReducer = (state = initialState, action) => {
  switch (action.type) {
    case dailyScoopConstants.GET_ALL_DAILY_SCOOP_SUCCESS:
      return {
        ...state,
        newDailyScoopEntries: action.allDailyScoops.docs,
        newEntryLoading: true,
        newEntryError: null,
        totalCount: action.allDailyScoops.totalDocs,
      };
    case dailyScoopConstants.GET_ALL_DAILY_SCOOP_FAILURE:
      return {
        ...state,
        newDailyScoopEntries: [],
        newEntryLoading: false,
        newEntryError: action.newEntryError,
      };
    case dailyScoopConstants.GET_DAILY_SCOOP_SUCCESS:
      return {
        ...state,
        newDailyScoopEntries: action.blogs,
        newEntryLoading: true,
        newEntryError: null,
      };
    case dailyScoopConstants.GET_DAILY_SCOOP_FAILURE:
      return {
        ...state,
        newDailyScoopEntries: [],
        newEntryLoading: false,
        newEntryError: action.newEntryError,
      };

    case dailyScoopConstants.GET_DAILY_SCOOP_BY_ID_SUCCESS:
      return {
        ...state,
        newEntryDescription: action.blogs,
        newEntryLoading: true,
        newEntryError: null,
      };
    case dailyScoopConstants.GET_DAILY_SCOOP_BY_ID_FAILURE:
      return {
        ...state,
        newEntryDescription: [],
        newEntryLoading: false,
        newEntryError: action.newEntryError,
      };

    case dailyScoopConstants.GET_DAILY_SCOOP_BY_TAG_SUCCESS:
      return {
        ...state,
        newEntry: action.blogs.docs,
        newEntryLoading: true,
        newEntryError: null,
        totalCountTag: action.blogs.totalDocs,
      };
    case dailyScoopConstants.GET_DAILY_SCOOP_BY_TAG_FAILURE:
      return {
        ...state,
        newEntry: [],
        newEntryLoading: false,
        newEntryError: action.newEntryError,
      };

    default:
      return state;
  }
};
export { dailyScoopReducer };
