import axios from "axios";
const apiURL = process.env.REACT_APP_BACKEND_URL;

// get data
const getDataRequest = (tag) => {
  let token = localStorage.getItem("auth-token");

  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
  };
  return axios.get(`${apiURL}/dailyScoops/${tag}`, config);
};

// get up trending data
const getUpTrendingRequest = () => {
  let token = localStorage.getItem("auth-token");

  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
  };

  return axios.get(`${apiURL}/allUpTrendings`, config);
};

// get dailyScoop data
const getDailyScoopRequest = () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios.get(`${apiURL}/dailyScoop`, config);
};

// get dailyScoop data by id
const getDailyScoopRequestbyid = (id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios.get(`${apiURL}/dailyScoop/${id}`, config);
};

// get dailyScoop data by tag
const getDailyScoopByTagRequest = (tag) => {
  let token = localStorage.getItem("auth-token");

  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
  };

  return axios.get(`${apiURL}/dailyScoops/${tag}`, config);
};

// update data by ID
const updateDataByID = (data, id) => {
  let token = localStorage.getItem("auth-token");

  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
  };

  return axios.put(`${apiURL}/dailyScoop/${id}`, data, config);
};

// Post data
const addDataRequest = (data, path) => {
  let token = localStorage.getItem("auth-token");
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: token,
    },
  };

  return axios.post(`${apiURL}/${path}`, data, config);
};

// Add up trending data
const addUpTrendingDataRequest = (data) => {
  let token = localStorage.getItem("auth-token");

  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
  };

  let result = axios.post(`${apiURL}/addDailyScoop`, data, config);
  return result;
};

// put data
const putDataRequest = (data) => {
  let accessTokan = "Test";
  return axios.put(
    `${apiURL}/url`,
    {
      ...data,
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

// delete data
const deleteDataRequest = (id) => {
  let token = localStorage.getItem("auth-token");

  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
  };

  return axios.delete(`${apiURL}/dailyScoop/${id}`, config);
};

export const dashboardApi = {
  updateDataByID,
  getDataRequest,
  getDailyScoopRequest,
  addDataRequest,
  putDataRequest,
  deleteDataRequest,
  getDailyScoopByTagRequest,
  getUpTrendingRequest,
  addUpTrendingDataRequest,
  getDailyScoopRequestbyid,
};
