import { Button, Col, Empty, Image, Row, Typography } from "antd";
import React from "react";
import styles from "../Styles/magazineSubscribe.module.css";
import { Link } from "react-router-dom";
import { ImagecustomePlace } from "./ImagecustomePlace";
import { v4 as uuidv4 } from "uuid";
import { fallback } from "../../Common/utils/faultTolerant";
const { Text, Title } = Typography;

const MagazineSubscribe = ({ magazines, onCover }) => {
  let getDescriptionElement = (description) => {
    if (description !== undefined) {
      const doc = new DOMParser().parseFromString(description, "text/html");

      const divs = doc.querySelectorAll("div");
      let extractedTexts = [];

      for (let i = 0; i < divs.length; i++) {
        extractedTexts.push(divs[i].textContent.trim());
      }
      let combinedText = extractedTexts.join(" ");
      return combinedText;
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={16}
        xl={16}
        style={{
          background: "#053C7C",
          paddingTop: "5rem",
          paddingBottom: "5rem",
        }}
      >
        <div className={styles.titleWrapper}>
          <Title className={styles.title}>ON THE COVER</Title>
        </div>
        <div className={styles.divider}></div>
        {onCover?.length === 0 || onCover === undefined ? (
          <Empty
            style={{ marginTop: "100px" }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ) : (
          <>
            <Link to={`/magazine/${onCover?.[0]?.redirectURL}`}>
              <Row
                gutter={[16, 16]}
                justify={"center"}
                style={{ marginTop: "30px", marginBottom: "40px" }}
              >
                <Col
                  xs={{ span: 22 }}
                  sm={{ span: 22 }}
                  md={{ span: 13 }}
                  lg={{ span: 13 }}
                  xl={{ span: 13 }}
                >
                  <Image
                    src={onCover?.[0]?.poster}
                    width={"100%"}
                    height={"100%"}
                    alt="Up trending"
                    preview={false}
                    fallback={fallback}
                  />
                </Col>
                <Col xs={{ span: 22 }} sm={{ span: 22 }} md={9} lg={9} xl={9}>
                  <Typography.Paragraph
                    ellipsis={{ rows: 3 }}
                    className={styles.texttitle}
                  >
                    {onCover?.[0]?.title}
                  </Typography.Paragraph>
                  <Typography.Paragraph
                    ellipsis={{ rows: 8 }}
                    className={styles.textdesc}
                  >
                    {getDescriptionElement(onCover?.[0]?.description)}
                  </Typography.Paragraph>
                </Col>
              </Row>
            </Link>
            <Row gutter={[40, 16]} justify={"center"}>
              {onCover?.slice(1, 5)?.map((data) => (
                <Col key={uuidv4()} xs={20} sm={11} md={11} lg={11}>
                  <Link to={`/magazine/${data?.redirectURL}`}>
                    <Image
                      src={data?.poster}
                      alt="up trending"
                      width={"100%"}
                      height={"auto"}
                      preview={false}
                      style={{
                        paddingBottom: "25px",
                      }}
                      fallback={fallback}
                    />
                    <Typography.Paragraph
                      ellipsis={{ rows: 2 }}
                      className={styles.texttitle}
                    >
                      {data?.title}
                    </Typography.Paragraph>
                    <Typography.Paragraph
                      ellipsis={{ rows: 4 }}
                      className={styles.textdesc}
                    >
                      {getDescriptionElement(data?.description)}
                    </Typography.Paragraph>
                  </Link>
                </Col>
              ))}
            </Row>
          </>
        )}
      </Col>
      <Col
        xs={{ span: 18, offset: 3 }}
        sm={{ span: 12, offset: 6 }}
        md={{ span: 12, offset: 6 }}
        lg={{ span: 6, offset: 1 }}
        xl={{ span: 6, offset: 1 }}
        style={{ marginTop: "6.5rem" }}
      >
        <div style={{textAlign:'center'}}>
        <ImagecustomePlace data={magazines} />
        </div>
        <div className={styles.rightsub}>
          <div className={styles.subscribe}>
            <Link to={"/subscribe"}>
              <Button className={styles.submitbtn}>SUBSCRIBE</Button>
            </Link>
          </div>
          <Text className={styles.righttext} style={{ fontWeight: "400" }}>
            Visionary Vogues Magazine is a platform for leaders, entrepreneurs,
            and organizations from across the globe to showcase their stories.
            We feature stories that are inspiring, unique, and extremely
            insightful. Want lots of free goodies? Subscribe and get something
            fresh every week
          </Text>
          <Text
            style={{ display: "block", fontWeight: 700, fontSize: "1.5em" }}
          >
            www.visionaryvogues.com
          </Text>
        </div>
        {/* </Row> */}
      </Col>
    </Row>
  );
};

export default MagazineSubscribe;
