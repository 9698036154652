import React from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Layout,
  Row,
  Typography,
  Upload,
  message,
} from "antd";
import guestBanner from "../Assets/guestPost/guest_post.png";
import guidelinesImage from "../Assets/guestPost/guide_submit.png";
import whySubmitImage from "../Assets/guestPost/why_submit_guest_post.png";
import styles from "./styles/guestPost.module.css";
import { useDispatch } from "react-redux";
import { contactUsAction } from "./state/actions";
import { UploadOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;

const GuestPost = () => {
  const dispatch = useDispatch();

  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const handleSubmit = (values) => {
    dispatch(contactUsAction.AddContactUsModalData(values, "guest"));
    form.resetFields();
    messageApi.open({
      type: "success",
      content: "Enquiry submitted successfully",
    });
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList[0]?.originFileObj;
  };

  const handleSubmitClick = () => {
    const formSection = document.getElementById("form");
    if (formSection) {
      formSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Content>
      {contextHolder}
      <section>
        <img src={guestBanner} alt="Privacy_policy" className={styles.image} />
        <div className={styles.overlay}>
          <Title
            style={{ display: "block", marginBottom: "0" }}
            className={styles.title}
          >
            Get your Guest Post Seen by the World.
          </Title>
          <Text style={{ display: "block" }} className={styles.text}>
            Visionary Vogues Magazine allows you to submit your guest posts in
            its website and share it with it’s ever-growing community of readers
            and clients. We encourage experts and bloggers to contribute to our
            platform with their high-quality content. We accept guest posts from
            a wide-variety of categories and niches. Furthermore, our team’s
            fast turnaround time makes it one of the most attractive options for
            writers and outreach brands.
          </Text>

          <div className={styles.buttonWrapper}>
            <button type="submit" onClick={handleSubmitClick}>
              Submit Guest Post
            </button>
          </div>
        </div>
      </section>

      {/* Form section */}
      <div className={styles.formSection} id="form">
        <div className={styles.formSection_textWrapper}>
          <Text
            style={{ display: "block" }}
            className={styles.formSection_text}
          >
            To submit your guest posts, fill the below form or email at
          </Text>
          <Text
            style={{ display: "block" }}
            className={styles.formSection_textBold}
          >
            info@visionaryvoguemagazine.com
          </Text>
          <Text
            style={{ display: "block" }}
            className={styles.formSection_text}
          >
            Please note that we receive multiple requests everyday for guest
            post publishing. We would ideally revert back to you in 2-3 working
            days about the status of your submission and how to proceed forward.
          </Text>
        </div>

        <Row gutter={[16, 16]} justify={"center"}>
          <Col xs={24} sm={24} md={20} lg={18} xl={18} xxl={20}>
            <Form
              form={form}
              layout="vertical"
              style={{ textAlign: "left" }}
              onFinish={(formvalues) => handleSubmit(formvalues)}
            >
              <Form.Item name={"name"}>
                <Input
                  className={styles.formInput}
                  placeholder="Your name"
                  type="text"
                />
              </Form.Item>
              <Form.Item
                name="email"
                required
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input placeholder="@email.com" className={styles.formInput} />
              </Form.Item>
              <Form.Item
                name={"website"}
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                  {
                    type: "url",
                    message: "This field must be a valid url.",
                  },
                ]}
              >
                <Input placeholder="Website" className={styles.formInput} />
              </Form.Item>
              <Form.Item name={"message"} rules={[{ required: true }]}>
                <TextArea
                  rows={4}
                  placeholder="Message"
                  className={styles.formInput}
                />
              </Form.Item>
              <Form.Item
                name={"file"}
                valuePropName="file"
                getValueFromEvent={normFile}
              >
                <Upload
                  maxCount={1}
                  beforeUpload={() => false}
                  onChange={(info) => {
                    // const { fileList } = info;
                  }}
                >
                  <Button
                    style={{ width: "fit-content" }}
                    icon={<UploadOutlined />}
                  >
                    Choose File
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item
                rules={[{ required: true }]}
                style={{ textAlign: "left" }}
              >
                <Checkbox className={styles.formInputCheckbox}>
                  Tick this box to prove you're not a robot.
                </Checkbox>
              </Form.Item>
              {/* <div style={{ textAlign: "end", paddingTop: "10px" }}>
                <Button htmlType="submit" className={contactStyles.sendBtn}>
                  Submit guest post
                </Button>
              </div> */}
              <div className={styles.buttonWrapper}>
                <button type="submit">Submit Guest Post</button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
      <Image
        src={guidelinesImage}
        alt="guidlines_to_guest_post_submit"
        preview={false}
        width={"100%"}
        height={"auto"}
      />
      <div style={{ padding: "25px", marginTop: "20px", marginBottom: "20px" }}>
        <Image
          src={whySubmitImage}
          alt="guidlines_to_guest_post_submit"
          preview={false}
        />
      </div>
    </Content>
  );
};

export { GuestPost };
