const newsData = [
  {
    label: "Add Content",
    key: "addContent",
  },
  {
    label: "Business",
    key: "business",
  },
  {
    label: "Technology",
    key: "technology",
  },
  {
    label: "Finance",
    key: "finance",
  },
  {
    label: "Marketing",
    key: "marketing",
  },
  {
    label: "Healthcare",
    key: "healthcare",
  },
  {
    label: "Consulting",
    key: "consulting",
  },
  {
    label: "Education",
    key: "education",
  },
  {
    label: "Manufacturing",
    key: "manufacturing",
  },
  {
    label: "Sports and Fitness",
    key: "sportsAndFitness",
  },
  {
    label: "Other",
    key: "other",
  },
  {
    label: "Up-trending",
    key: "upTrending",
  },
  {
    label: "Special Profile",
    key: "specialProfile",
  },
  {
    label: "Magazines",
    key: "magazine",
  },
  {
    label: "Inquiries",
    key: "inquiries",
  },
];

const options = [
  { label: "Business", value: "business" },
  { label: "Manufacturing", value: "manufacturing" },
  { label: "Technology", value: "technology" },
  { label: "Education", value: "education" },
  { label: "Healthcare", value: "healthcare" },
  { label: "Marketing", value: "marketing" },
  { label: "Finance", value: "finance" },
  { label: "Consulting", value: "consulting" },
  { label: "Leadership", value: "leadership" },
  { label: "Sports and Fitness", value: "sportsAndFitness" },
  { label: "Other", value: "other" },
];

module.exports = {
  newsData,
  options
};
