import React from "react";
import {  useSelector } from "react-redux";
import { Link} from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import { Helmet } from "react-helmet-async";
import styles from "../Styles/upTrending.module.css";
import { SubscribeSection } from "../../Common";
import { Col, Row, Typography } from "antd";
import { ErrorHandling } from "../../ErrorHandling/ErrorHandling";
import { SocialShare } from "../../Common/Components/SocialShare";
import DailyScoop from "../../Home/Components/DailyScoop";
const { Title } = Typography;

const UptrendingDescription = () => {
  // useEffect(() => {
  //   dispatch(upTrendingAction.getUpTrendingDataById(id, "user", 1));
  //   dispatch(homeAction.getHomeModalData());
  // }, [dispatch, id]);

  //before remove or change this section contact Mahesh
  // useEffect(() => {
  //   dispatch(homeAction.getHomeModalData());
  // }, [dispatch]);

  // useEffect(() => {
  //   dispatch(upTrendingAction.getUpTrendingData("user", 1));
  // }, [dispatch]);

  const { homeData } = useSelector((state) => state.homeReducer);
  const { article } = useSelector(str => str.commonReducer);
  let { newEntry } = useSelector((state) => state.upTrendingReducer);
  const { isLoading, message, messageID } = useSelector(
    (str) => str.loadingReducer
  );
  if (isLoading) {
    return <Spinner />;
  }

  if (typeof message === "object") {
    return (
      <ErrorHandling code={message?.status} statusText={message?.statusText} />
    );
  }
  if (typeof messageID === "object") {
    return (
      <ErrorHandling
        code={messageID?.status}
        statusText={messageID?.statusText}
      />
    );
  }

  let getConten = (data) => {
    if (data?.description) {
      const doc = new DOMParser().parseFromString(
        data.description,
        "text/html"
      );

      const divs = doc.querySelectorAll("div");
      let extractedTexts = [];

      for (let i = 0; i < divs.length; i++) {
        const hasImg = divs[i].querySelector("img");
        if (!hasImg) {
          extractedTexts.push(divs[i].textContent.trim());
        }
      }
      let combinedText = extractedTexts.slice(0, 3).join(" ");
      return combinedText;
    }
    return "Thanks";
  };

  return (
    <>
      <Helmet>
        <meta name="keywords" content={article?.keywords}></meta>
        <title>{article?.title}</title>
        <meta name="description" content={article?.metaDescription ? article?.metaDescription : "Visionary Vogue: Where Business Transformation Takes Center Stage"} />

        <meta name="twitter:card" content={"type"} />
        <meta name="twitter:title" content={article?.title} />
        <meta name="twitter:description" content={article?.metaDescription ? article?.metaDescription : "Visionary Vogue: Where Business Transformation Takes Center Stage"} />

        <meta property="og:image" content={article?.thumbnail} />
        <meta property="og:description" content={article?.metaDescription ? article?.metaDescription : "Visionary Vogue: Where Business Transformation Takes Center Stage"} />
        <meta property="og:title" content={article?.title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:URL"
          content={`https://visionaryvogues.com/${article?.redirectURL}`}
        />
      </Helmet>

      <div className={styles.dailyScoopTitleWrapper}>
        <Link to={"/up-trending"}>
          <Title className={styles.dailyScoopTitle}>up-trending</Title>
        </Link>
      </div>
      <Row
        gutter={[0, 16]}
        style={{
          justifyContent: "center",
          marginTop: "50px",
          marginBottom: "20px",
          overflowx: "hidden",
        }}
      >
        <Col
          xs={22}
          sm={22}
          md={22}
          lg={15}
          xl={17}
          className={styles.descContent}
        >
          <SocialShare
            url={`https://visionaryvogues.com/${article?.redirectURL}`}
            content={getConten(article)}
          />
          <div
            className={styles.descImg}
            dangerouslySetInnerHTML={{
              __html: article?.description,
            }}
          />
        </Col>
        <Col
          xs={22}
          sm={22}
          md={22}
          lg={8}
          xl={6}
          style={{ position: "sticky", top: 0, height: "max-content" }}
        >
          <SubscribeSection prop={"uptrending"} newEntry={newEntry} />
        </Col>
      </Row>
      <DailyScoop dailyScoops={homeData?.onCover} related={"On The Cover"} alignment={"poster"} />
    </>
  );
};

export default UptrendingDescription;
