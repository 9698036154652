import React, { useEffect, useState } from "react";
import { Col, Empty, Layout, Pagination, Row, Typography } from "antd";
import { v4 as uuidv4 } from "uuid";
import styles from "../Styles/business.module.css";
import VerticalCard from "../../Common/Components/VerticalCard";
import { dailyScoopAction } from "../state/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import { constants } from "../../Common/Components/Constants";
import { Helmet } from "react-helmet-async";
// import { Helmet } from "react-helmet-async";

const { Content } = Layout;
const { Title } = Typography;

const DailyScoopByTag = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const paramText = useParams();
  const { newEntry, totalCountTag } = useSelector(
    (state) => state.dailyScoopReducer
  );
  const { isLoading } = useSelector((str) => str.loadingReducer);

  useEffect(() => {
    setPage(1);
    dispatch(
      dailyScoopAction.getDailyScoopByTagEvent(paramText.tag, "user", 1)
    );
  }, [dispatch, paramText.tag]);

  const handlePageChange = (page) => {
    setPage(page);
    dispatch(
      dailyScoopAction.getDailyScoopByTagEvent(paramText.tag, "user", page)
    );
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Content style={{ overflowX: "hidden" }}>
      <Helmet>
        <meta name="keywords" content={`${paramText.tag} | Visionary Vogues`}></meta>
        <title>{`${paramText.tag?.[0]?.toUpperCase() + paramText.tag?.slice(1)} | Visionary Vogues`}</title>
        <meta name="description" content={constants.dailyScoopTag.description} />

        <meta name="twitter:card" content="type" />
        <meta name="twitter:title" content={`${paramText.tag?.[0]?.toUpperCase() + paramText.tag?.slice(1)} | Visionary Vogues`}/>
        <meta name="twitter:description" content={constants.dailyScoopTag.description} />

        <meta property="og:title" content={`${paramText.tag?.[0]?.toUpperCase() + paramText.tag?.slice(1)} | Visionary Vogues`} />
        <meta property="og:description" content={constants.dailyScoopTag.description} />
        <meta property="og:type" content="website" />
        <meta property="og:URL" content={`https://visionaryvogues.com/daily-scoop/${paramText.tag}`} />
      </Helmet>

      <div className={styles.dailyScoopTitleWrapper}>
        <Title
          style={{ display: "block", marginTop: "20px" }}
          className={styles.dailyScoopTitle}
        >
          {paramText.tag === "sportsAndFitness" ? "SPORTS AND FITNESS" : paramText.tag}
        </Title>
      </div>
      {newEntry?.length === 0 || newEntry === undefined ? (
        <Empty
          style={{ marginTop: "100px" }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      ) : (
        <Row
          style={{
            paddingTop: "40px",
            paddingRight: "20px",
            paddingLeft: "20px",
            overflowX: "hidden",
          }}
        >
          {newEntry?.map((data) => (
            <Col xs={24} sm={12} md={8} lg={8} xl={6} key={uuidv4()}>
              <VerticalCard
                image={data?.thumbnail}
                title={
                  data?.title?.length > 60
                    ? data?.title?.slice(0, 60) + "..."
                    : data?.title
                }
                tag={data?.tag}
                textType={1}
                subPath={data?.redirectURL}
                data={data}
              />
            </Col>
          ))}
        </Row>
      )}
      <Row
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
          marginBottom: "40px",
        }}
      >
        <Pagination
          defaultCurrent={1}
          total={totalCountTag}
          onChange={handlePageChange}
          current={page}
          pageSize={12}
          hideOnSinglePage={true}
          showSizeChanger={false}
        />
      </Row>
    </Content>
  );
};

export default DailyScoopByTag;
