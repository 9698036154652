import { dailyScoopConstants } from "./action-types";
import {
  showMessageError,
  showMessageIdError,
  showMessageIdSuccess,
  showMessageSuccess,
  startDailyscoopLoading,
  startLoading,
  stopDailyscoopLoading,
  stopLoading,
} from "../../state/actions";
import { routesConstants } from "../../Common/utils/allroutes";
import { dailyScoopApi } from "../utils/api";

const dashboardGetDailyScoopError = (err) => {
  return {
    type: dailyScoopConstants.GET_DAILY_SCOOP_FAILURE,
    newEntryError: err,
  };
};

const dashboardGetDailyScoopSuccess = (data) => {
  return {
    type: dailyScoopConstants.GET_DAILY_SCOOP_SUCCESS,
    blogs: data,
  };
};

const dashboardGetDailyScoopByIdError = (err) => {
  return {
    type: dailyScoopConstants.GET_DAILY_SCOOP_BY_ID_FAILURE,
    newEntryError: err,
  };
};

const dashboardGetDailyScoopByIdSuccess = (data) => {
  return {
    type: dailyScoopConstants.GET_DAILY_SCOOP_BY_ID_SUCCESS,
    blogs: data,
  };
};

const getDailyScoopByIDEvent = (role, id) => {
  return (dispatch) => {
    dispatch(startLoading());
    let path = routesConstants.dailyScoops.getById;
    dailyScoopApi
      .getDataRequest(path, id, role, 1)
      .then((response) => {
        dispatch(dashboardGetDailyScoopByIdSuccess(response.data.info));
        dispatch(stopLoading());
        dispatch(showMessageIdSuccess("Fetched Successfully"));
      })
      .catch((err) => {
        dispatch(dashboardGetDailyScoopByIdError(err));
        dispatch(stopLoading());
        dispatch(showMessageIdError(err.response));
      });
  };
};

const dashboardGetDailyScoopByTagError = (err) => {
  return {
    type: dailyScoopConstants.GET_DAILY_SCOOP_BY_TAG_FAILURE,
    newEntryError: err,
  };
};

const dashboardGetDailyScoopByTagSuccess = (data) => {
  return {
    type: dailyScoopConstants.GET_DAILY_SCOOP_BY_TAG_SUCCESS,
    blogs: data,
  };
};

const getDailyScoopEvent = (role, page) => {
  return (dispatch) => {
    dispatch(startDailyscoopLoading());
    let path = routesConstants.dailyScoops.get;
    dailyScoopApi
      .getApiRequest(path, role, page)
      .then((response) => {
        dispatch(dashboardGetDailyScoopSuccess(response.data.info));
        dispatch(stopDailyscoopLoading());
        dispatch(showMessageSuccess("Fetched Successfully"));
      })
      .catch((err) => {
        dispatch(dashboardGetDailyScoopError(err));
        dispatch(stopDailyscoopLoading());
        dispatch(showMessageError(err?.response));
      });
  };
};

const getDailyScoopByTagEvent = (tag, role, page) => {
  return (dispatch) => {
    let path = routesConstants.dailyScoops.getByTag;
    dispatch(startLoading());
    dailyScoopApi
      .getDataRequest(path, tag, role, page)
      .then((response) => {
        dispatch(dashboardGetDailyScoopByTagSuccess(response.data.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(dashboardGetDailyScoopByTagError(err));
        dispatch(stopLoading());
      });
  };
};

const dashboardGetAllDailyScoopError = (err) => {
  return {
    type: dailyScoopConstants.GET_ALL_DAILY_SCOOP_FAILURE,
    newEntryError: err,
  };
};

const dashboardGetAllDailyScoopSuccess = (data) => {
  return {
    type: dailyScoopConstants.GET_ALL_DAILY_SCOOP_SUCCESS,
    allDailyScoops: data,
  };
};

const getAllDailyScoopEvent = (role, page) => {
  return (dispatch) => {
    dispatch(startDailyscoopLoading());
    let path = routesConstants.dailyScoops.getAllDailyScoop;
    dailyScoopApi
      .getApiRequest(path, role, page)
      .then((response) => {
        dispatch(dashboardGetAllDailyScoopSuccess(response.data.info));
        dispatch(stopDailyscoopLoading());
        dispatch(showMessageSuccess("Fetched Successfully"));
      })
      .catch((err) => {
        dispatch(dashboardGetAllDailyScoopError(err));
        dispatch(stopDailyscoopLoading());
        dispatch(showMessageError(err?.statusCode));
      });
  };
};

export const dailyScoopAction = {
  getDailyScoopEvent,
  getDailyScoopByTagEvent,
  getDailyScoopByIDEvent,
  getAllDailyScoopEvent,
};
