import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { commonAction } from "../State/actions";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../Spinner/Spinner";
import DailyScoopDescription from "../../DailyScop/Components/DailyScoopDescription";
import { homeAction } from "../../Home/state/actions";
import { dailyScoopAction } from "../../DailyScop/state/actions";
import { upTrendingAction } from "../../UpTrending/state/actions";
import UptrendingDescription from "../../UpTrending/Components/UptrendingDescription";
import MagazineDescription from "../../Magazines/MagazineDescription";
import { ErrorHandling } from "../../ErrorHandling/ErrorHandling";
import { MagazineListing } from "../../MagazineListing";
import { magazineAction } from "../../Magazines/state/actions";

const DescriptionPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isLoading, isDailyscoopLoading } = useSelector(
    (str) => str.loadingReducer
  );
  const { article, articleError } = useSelector((str) => str.commonReducer);

  useEffect(() => {
    dispatch(commonAction.getArticleBasedonURL(id, "user"));
  }, [id]);

  useEffect(() => {
    if (article && article?.type === "dailyscoop") {
      dispatch(homeAction.getHomeModalData());
      dispatch(dailyScoopAction.getAllDailyScoopEvent("user", 1));
    } else if (article && article?.type === "uptrending") {
      dispatch(homeAction.getHomeModalData());
      dispatch(upTrendingAction.getUpTrendingData("user", 1));
    } else if (article && article?.type === "magazine") {
      dispatch(magazineAction.getMagazineModalData("user", 1));
    }
    // console.log("got a artical", article);
    // assignMetaDataWithId(article);
  }, [article]);

  useEffect(() => {
    return () => {
      dispatch(commonAction.clearArticle());
    };
  }, []);

  if (isLoading || isDailyscoopLoading) {
    return <Spinner />;
  }

  return (
    article ?
      (article?.type === "dailyscoop" ? (
        <DailyScoopDescription />
      ) : article?.type === "uptrending" ? (
        <UptrendingDescription />
      ) : (
        <MagazineListing />
      )) : articleError ? (
        <ErrorHandling />
      ) : null
  );
};

export default DescriptionPage;
