import React, { useState } from "react";
import { Button, Modal, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 / 1024 / 1024 / 1024 / 1024 < 6;
  if (!isLt2M) {
    message.error("Image must smaller than 6MB!");
  }
  return isLt2M;
};

const beforeuploadpdf = (file) => {
  const isPdf = file.type === "application/pdf";
  if (!isPdf) {
    message.error("You can only upload pdf file!");
  }
  return isPdf;
};

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

function ImageUpload({
  maxCount,
  handleThumbnailChange,
  origin,
  value,
  onChange,
}) {
  const [previewImage, setPreviewImage] = useState({
    imageName: "",
    imageFile: "",
    isimageVisible: false,
  });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage({
      ...previewImage,
      imageName: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
      imageFile: file.url || file.preview,
      isimageVisible: true,
    });
  };

  const handleCancel = () => {
    setPreviewImage({
      ...previewImage,
      imageName: "",
      imageFile: "",
      isimageVisible: false,
    });
  };
  return (
    <>
      <Upload
        multiple={origin === "insights" ? true : false}
        listType="text"
        onPreview={(e) => handlePreview(e)}
        onChange={onChange}
        beforeUpload={origin === "uploadPdf" ? beforeuploadpdf : beforeUpload}
        style={{ cursor: "pointer" }}
        maxCount={maxCount}
        customRequest={dummyRequest}
      >
        <Button icon={<UploadOutlined />}>Upload</Button>
      </Upload>
      <Modal
        open={previewImage?.isimageVisible}
        title={previewImage?.imageName}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage?.imageFile}
        />
      </Modal>
    </>
  );
}
export { ImageUpload };
