import { Empty, Image } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import styles from "../Styles/magazineSubscribe.module.css";
import { useMediaQuery } from "react-responsive";
import { v4 as uuidv4 } from "uuid";

function ImagecustomePlace({ data }) {
  const issmallscreen = useMediaQuery({ maxWidth: 768 });
  const ismediumscreen = useMediaQuery({ maxWidth: 1100 });
  return (
    <div>
      {data?.length === 0 || data === undefined ? (
        <Empty
          style={{ marginTop: "100px" }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      ) : (
        data?.slice(0, 3).map((element, index) => (
          <Link key={uuidv4()} to={`/magazine/${element?.redirectURL}`}>
            <Image
              preview={false}
              alt="example"
              src={element?.thumbnail}
              width={"100%"}
              style={{
                width: ismediumscreen
                  ? "220px"
                  : !issmallscreen
                  ? "280px"
                  : "180px",
                height: "auto",
                border: "10px solid black",
              }}
              className={
                index === 0
                  ? styles.imageOneCss
                  : index === 1
                  ? styles.imageTwoCss
                  : styles.imageThreeCss
              }
            />
          </Link>
        ))
      )}
    </div>
  );
}

export { ImagecustomePlace };
