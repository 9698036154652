import React, { useState } from "react";
import { Navigate, useNavigate, Outlet, useParams } from "react-router-dom";
import { Layout, Menu } from "antd";
import { newsData } from "../../Common/commondata";
import { useDispatch } from "react-redux";
import { messageClear } from "../../state/actions";
import { clearNewEntry } from "../../Dashboard/state/actions";
const { Content, Sider } = Layout;

const PrivateRoute = () => {
  const { type } = useParams();
  const navigate = useNavigate();
  let token = localStorage.getItem("auth-token");
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(true);

  let handleSelect = (selected) => {
    if (selected.key === "addContent") {
      dispatch(messageClear());
      dispatch(clearNewEntry());
      navigate(`/dashboard/addContent/dailyScoops`);
    } else {
      dispatch(clearNewEntry());
      navigate(`/dashboard/${selected.key}`);
    }
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
        minWidth: "100vw",
      }}
    >
      {token && token !== "null" ? (
        <Layout
          style={{
            minHeight: "100vh",
            minWidth: "100vw",
          }}
        >
          <Sider
            width={200}
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
          >
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={type === undefined ? [""] : type}
              style={{
                height: "100%",
                borderRight: 0,
              }}
              onSelect={handleSelect}
              items={newsData}
            />
          </Sider>
          <Content
            style={{
              margin: 0,
              background: "White",
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      ) : (
        <Navigate to="/login" />
      )}
    </Layout>
  );
};

export { PrivateRoute };
