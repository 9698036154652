import React, { useEffect } from "react";
import { Col, Form, Input, Row } from "antd";
import { ImageUpload } from "../../Common/Components/ImageUpload";
import { ReactQuillComponentRender } from "./ReactQuillComponentRender";

const { TextArea } = Input;

function EditUptrending({ values, handleThumbnailChange, form, setDescValue }) {
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList[0]?.originFileObj;
  };
  useEffect(() => {
    if (Object.keys(values).length > 0) {
      form.setFieldsValue(values);
    }
  }, [values]);

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        label="Title"
        name="title"
        rules={[
          {
            required: true,
            message: "Title is required",
          },
        ]}
      >
        <TextArea placeholder="Title" autoSize />
      </Form.Item>
      <Form.Item
        label="Thumbnail"
        name="thumbnail"
        rules={[
          {
            required: true,
            message: "Thumbnail is required",
          },
        ]}
        getValueFromEvent={normFile}
      >
        <ImageUpload
          origin="thumbnail"
          handleThumbnailChange={handleThumbnailChange}
          maxCount={1}
        />
      </Form.Item>
      <Form.Item
        label="Key words"
        name="keywords"
        rules={[
          {
            required: true,
            message: "Please add Keywords!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Redirect URL"
        name="redirectURL"
        rules={[
          {
            required: true,
            message: "Please add Redirect URL!",
          },
        ]}
      >
        <Input style={{ textAlign: "left" }} placeholder="Redirect URL" />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        rules={[
          {
            required: true,
            message: "Please add description!",
          },
        ]}
      >
        <ReactQuillComponentRender setDescValue={setDescValue} />
      </Form.Item>
    </Form>
  );
}
export { EditUptrending };
