import axios from "axios";

const apiURL = process.env.REACT_APP_BACKEND_URL;

// standered get api call
const getDataRequest = async (path, source,role) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.get(`${apiURL}/${path}/${role}?search=${source}`, config);
};

export const searchApi = {
  getDataRequest,
};
