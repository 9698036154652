export const dailyScoopConstants = {
  GET_ALL_DAILY_SCOOP_FAILURE: "GET_ALL_DAILY_SCOOP_FAILURE",
  GET_ALL_DAILY_SCOOP_SUCCESS: "GET_ALL_DAILY_SCOOP_SUCCESS",

  GET_DAILY_SCOOP_FAILURE: "GET_DAILY_SCOOP_FAILURE",
  GET_DAILY_SCOOP_SUCCESS: "GET_DAILY_SCOOP_SUCCESS",

  GET_DAILY_SCOOP_BY_TAG_FAILURE: "GET_DAILY_SCOOP_BY_TAG_FAILURE",
  GET_DAILY_SCOOP_BY_TAG_SUCCESS: "GET_DAILY_SCOOP_BY_TAG_SUCCESS",

  GET_DAILY_SCOOP_BY_ID_FAILURE: "GET_DAILY_SCOOP_BY_ID_FAILURE",
  GET_DAILY_SCOOP_BY_ID_SUCCESS: "GET_DAILY_SCOOP_BY_ID_SUCCESS",
};
