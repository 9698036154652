import React from "react";
import { Col, Image, Row, Typography } from "antd";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { fallback } from "../utils/faultTolerant";
import styles from "../Styles/horizontalCard.module.css";

const { Text } = Typography;

const HorizontalCard = ({
  image,
  title,
  description,
  button,
  buttonData,
  border,
  textType,
  isRound,
  orderType,
  date,
  subscribeStyle,
  tag,
  id,
  coverstories,
  path,
  goto,
  special,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const doc = new DOMParser().parseFromString(description, "text/html");
  const divs = doc.querySelectorAll("div");
  let extractedTexts = [];

  for (let i = 0; i < divs.length; i++) {
    const hasImg = divs[i].querySelector("img");
    if (!hasImg) {
      extractedTexts.push(divs[i].textContent.trim());
    }
  }
  let combinedText = extractedTexts.slice(0, 6).join(" ");
  const navigate = useNavigate();
  return (
    <div
      className={styles.cardType3}
      style={{
        borderBottom: border === true ? "1px solid #dcdcdc" : "0",
      }}
    >
      <Row
        style={{ justifyContent: "center" }}
        gutter={textType === 2 ? [1, 16] : textType === 5 ? [40, 16] : [0, 0]}
      >
        <Col
          className={`${subscribeStyle === "subscribeStyle" ? styles.subscribeCol : null
            }`}
          xs={path === "dailyScoop" ? 24 : 6}
          sm={9}
          md={6}
          lg={7}
          xl={8}
          xxl={9}
          order={orderType === 2 ? 2 : 1}
          style={{
            position: tag ? "relative" : "initial",
            // paddingLeft: "10px",
            justifyContent: "center",
            display: "flex",
            overflow: "hidden",
          }}
        >
          {/* <Link
            style={{ color: "black" }}
            to={goto ? `/up-trending/${id}` : subscribeStyle ? `/daily-scoop/${title}/${id}` : ""}
          > */}
          <Image
            preview={false}
            src={image}
            alt={title}
            width={path === "dailyScoop" ? "100%" : ""}
            height={special ? "220px" : "100%"}
            className={`${subscribeStyle === "subscribeStyle"
                ? styles.subscribeStyle
                : styles.image
              }`}
            style={{
              borderRadius: isRound === true ? "50%" : "0",
              cursor: "pointer",
            }}
            fallback={fallback}
            onClick={() =>
              navigate(
                goto
                  ? `/${id}`
                  : subscribeStyle
                    ? `/${id}`
                    : ""
              )
            }
          />
          {/* </Link> */}
          {tag ? (
            <Link
              to={
                path === "dailyScoop"
                  ? special
                    ? ""
                    : tag === "Magazine"
                      ? `/magazines`
                      : `/daily-scoop/${tag[0].toLowerCase() + tag.slice(1)}`
                  : "/example"
              }
            >
              <Text className={styles.tag}>
                {tag === "Magazine" ? null : tag}
              </Text>
            </Link>
          ) : (
            <></>
          )}
        </Col>
        <Col
          xs={path === "dailyScoop" ? 24 : 18}
          sm={15}
          md={18}
          lg={17}
          xl={16}
          xxl={15}
          style={{
            paddingTop: "0px",
            paddingRight: "10px",
            paddingBottom: "0px",
            paddingLeft: "10px",
            display:
              isMobile || textType === 2 || textType === 3 ? "flex" : "initial",
            flexDirection: "column",
            justifyContent: "space-around",
            gap: "6px",
          }}
          order={orderType === 2 ? 1 : 2}
        >
          <Link
            to={
              subscribeStyle
                ? `/daily-scoop/${title[0]?.toLowerCase() + title?.slice(1)}`
                : path === "upTrending"
                  ? `/${id}`
                  : path === "dailyScoop"
                    ? tag === "Magazine"
                      ? `/${id}`
                      : tag === "Up Trending"
                        ? `/up-trending`
                        : special
                          ? ""
                          : `/${id}`
                    : "/example"
            }
          >
            <Typography.Paragraph
              style={{ marginBottom: "0px" }}
              ellipsis={{ rows: 3, symbol: "..." }}
              className={`${textType === 2 ? styles.title1 : styles.title}`}
            >
              {title === "SportsAndFitness" ? "Sports and Fitness" : title}
            </Typography.Paragraph>
          </Link>
          {!coverstories ? (
            <Typography.Paragraph
              ellipsis={{ rows: 3, symbol: "..." }}
              className={`${textType === 2
                  ? styles.description1
                  : textType === 3
                    ? styles.description3
                    : styles.description
                }`}
            >
              <Link
                style={{ color: "black" }}
                to={
                  goto
                    ? `/${id}`
                    : subscribeStyle
                      ? `/${id}`
                      : ""
                }
              >
                {description}
              </Link>
            </Typography.Paragraph>
          ) : (
            <Typography.Paragraph
              ellipsis={{ rows: 6, symbol: "..." }}
              className={`${textType === 2
                  ? styles.description1
                  : textType === 3
                    ? styles.description3
                    : styles.description
                }`}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: combinedText,
                }}
              />
            </Typography.Paragraph>
          )}
          <div className={styles.dateWrapper}>
            <Text className={styles.date}>{date}</Text>
          </div>
          {button === true ? <button>{buttonData}</button> : ""}
        </Col>
      </Row>
    </div>
  );
};

export default HorizontalCard;
