import { addContentConstants } from "./action-types";

const initialState = {
  newEntry: false,
  newEntryLoading: true,
  newEntryError: null,
};

const addContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case addContentConstants.CONTENT_CREATION_SUCCESS:
      return {
        ...state,
        newEntry: action.newEntry,
        newEntryLoading: true,
        newEntryError: null,
      };
    case addContentConstants.CONTENT_CREATION_FAILURE:
      return {
        ...state,
        newEntry: [],
        newEntryLoading: false,
        newEntryError: action.newEntryError,
      };

    default:
      return state;
  }
};
export { addContentReducer };
