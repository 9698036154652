import React from "react";
import { Typography } from "antd";
import disclaimerBanner from "../Assets/static/disclaimer.png";
import styles from "./Styles/static.module.css";
import { Helmet } from "react-helmet-async";
import { constants } from "../Common/Components/Constants";
const { Title, Text } = Typography;

const Disclaimer = () => {
  return (
    <>
      <Helmet>
        <meta name="keywords" content={constants.disclaimer.title}></meta>
        <title>{constants.disclaimer.title}</title>
        <meta name="description" content={constants.disclaimer.description} />
        <meta property="og:title" content={constants.disclaimer.title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:URL"
          content={`https://visionaryvogues.com/disclaimer`}
        />
      </Helmet>
      <section>
        <img
          src={disclaimerBanner}
          alt="Privacy_policy"
          className={styles.image}
        />
        <div className={styles.overlay}>
          <Text style={{ display: "block" }} className={styles.text}>
            All opinions expressed are solely those of the authors, not
            endorsed.
          </Text>
        </div>
      </section>

      <div className={styles.container}>
        <Text className={styles.disclaimer}>General Disclaimer</Text>
        <ul style={{ paddingLeft: "20px" }}>
          <li>
            <Text className={styles.text}>
              Visionary Vogues is not in charge of material presented on this
              website and does not ensure the substance, exactness, or
              utilization of the substance in this web page. We don’t in any
              capacity endorse or prescribe people, items or administrations
              that might be talked about on this site. Visionary Vogues
              explicitly disclaims all risk for cases or harms that may result
              from any posting on this site.
            </Text>
          </li>
          <li>
            <Text className={styles.text}>
              We acknowledge no liability regarding the opinions and data posted
              on this gathering, and such feelings don’t really reflect our
              strategies. In no occasion will we be obligated to you or any
              other person for any choice made or move made by you in dependence
              on data on this site.
            </Text>
          </li>
          <li>
            <Text className={styles.text}>
              This list is not a ranking. The companies listed in the magazine
              serve different aspects of the market, making them rank in any
              order except revenue. We try to bring a perfect platform for
              business organizations to showcase their valued products/services.
            </Text>
          </li>
          <li>
            <Text className={styles.text}>
              Visionary Vogues does not authenticate any information related to
              companies/leaders published in this magazine. We are not
              responsible for any misinformation regarding the same.
            </Text>
          </li>
        </ul>
        <Text className={styles.privacy_Policy}>Understanding Use of Cookies</Text>
        <div
          style={{
            paddingLeft: "20px",
            display: "block",
            marginBottom: "20px",
          }}
        >
          <Text className={styles.text}>
            This website may use cookies to help the performance of the website
            and your online experience. A cookie is a snippet of data that is
            saved to the hard drive of your PC or cell phone. It recalls data
            about the configuration of your PC or cell phone and will recollect
            that you’ve visited previously. This improves your site browsing
            experience by storing data so you don’t need to give it a few times.
          </Text>
          <br />
          <br />
          <Text style={{ display: "block" }} className={styles.text}>
            A cookie normally doesn’t contain PII, yet other browsing details,
            for example, the domain from which the cookie has originated, an
            random unique identifier code and furthermore the lifetime (expiry)
            of the cookie. Cookies might be either “persistent” or “session”
            cookies. A persistent cookie will stay substantial and practical
            until its set expiry date (except if erased by the user before the
            expiry date); contrary a session cookie will expire when the
            internet browser is shut by the user.
          </Text>
        </div>
        <Text className={styles.privacy_Policy}>How We Use Cookies</Text>
        <div style={{ paddingLeft: "20px" }}>
          <Text className={styles.text}>
            We may use the information gathered by cookies to measure
            information about our website statistics. We can learn which
            sections and categories on our site are being viewed or searched and
            also the number of visitors, using the different sections of this
            website. This information helps us to improve the usability of the
            website on an ongoing basis.
          </Text>
          <br />
          <br />
          <Text className={styles.text}>
            The third party tool, which we use for assessing website analytics,
            is Google Analytics – a tool that provides monitoring and analysis
            services. Google Analytics reporting helps us understand the number
            of people visiting our website, most viewed pages on our website and
            helps us improve and develop our site. Google Analytics uses a
            cookie to track the number of unique users of the site, but does not
            enable us to identify any individuals.
          </Text>
          <br />
          <br />

          <Text className={styles.text}>
            We may use third party advertising companies to place advertisements
            about our products and services on other websites.
          </Text>
          <br />
          <br />
          <Text className={styles.text}>
            Please visit{" "}
            <a
              href="https://www.google.com/policies/privacy/ads"
              style={{ color: "black", textDecoration: "underline" }}
            >
              {" "}
              www.google.com/policies/privacy/ads{" "}
            </a>{" "}
            if you would like more information about online advertising we use.
          </Text>
        </div>
        <br />
        <br />
        <Text
          className={styles.text}
          style={{ fontWeight: "bolder", paddingLeft: "20px" }}
        >
          Please remember that, you can always disable the cookies from your
          browser settings.
        </Text>
      </div>
    </>
  );
};

export { Disclaimer };
