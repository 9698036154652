import { descriptionListingConstants } from "./action-types";

const initialState = {
  descriptionListing: [],
  descriptionListingLoading: true,
    escriptionListingError: null,
};

const descriptionListingReducer = (state = initialState, action) => {
  switch (action.type) {
    case descriptionListingConstants.LISTING_DESCRIPTION_SUCCESS:
      return {
        ...state,
        descriptionListing: action.descriptionListing,
        descriptionListingLoading: true,
        escriptionListingError: null,
      };
    case descriptionListingConstants.LISTING_DESCRIPTION_FAILURE:
      return {
        ...state,
        descriptionListing: [],
        descriptionListingLoading: false,
        escriptionListingError: action.escriptionListingError,
      };

    default:
      return state;
  }
};
export { descriptionListingReducer };
