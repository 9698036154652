import React from "react";
import { Image, Typography } from "antd";
import { Link } from "react-router-dom";
import styles from "../Styles/verticalCard2.module.css";
import { fallback } from "../utils/faultTolerant";

const VerticalCard2 = ({
  image,
  dateTag,
  title,
  path,
  magazinePath,
  textType,
  id,
  // typeOfPage
}) => {
  return (
    <Link
      style={{ color: "white" }}
      to={`${magazinePath
          ? `/magazines/${magazinePath}`
          : path
            ? `/listing/${path}`
            : id
              ? `/${id}`
              : "/example"
        }`}
      // state={{ typeOfPage: typeOfPage }}
    >
      <div
        className={styles.cardType1}
        style={{
          position: dateTag ? "relative" : "initial",
          boxShadow:
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
        }}
      >
        <Image
          src={image}
          alt={title}
          preview={false}
          width={"100%"}
          className={styles.cardType1Image}
          fallback={fallback}
        />
        <div style={{ marginBottom: "16px" }}></div>
        <div className={styles.textWrapper}>
          <Typography.Paragraph
            ellipsis={{
              rows: 3,
              width: "90%",
              expandable: true,
              symbol: "Read More",
            }}
            className={textType === 2 ? styles.title2 : styles.title}
          >
            {title}
          </Typography.Paragraph>
        </div>
      </div>
    </Link>
  );
};

export default VerticalCard2;
