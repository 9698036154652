import { magazineConstants } from "./action-types";
import { magazineApi } from "../utils/api";
import { routesConstants } from "../../Common/utils/allroutes";
import {
  showMessageError,
  showMessageMagazineError,
  showMessageMagazineSuccess,
  showMessageSuccess,
  startLoading,
  stopLoading,
} from "../../state/actions";

/**
 * To update Navbar title action
 * @param {string}
 */

const MagazineModalError = (err) => {
  return {
    type: magazineConstants.GET_MAGAZINE_DATA_FAILURE,
    magazineDataError: err,
  };
};

const MagazineModalSuccess = (data) => {
  return {
    type: magazineConstants.GET_MAGAZINE_DATA_SUCCESS,
    magazineData: data,
  };
};

const getMagazineModalData = (role, page) => {
  return (dispatch) => {
    dispatch(startLoading());
    let path = routesConstants.magazine.get;
    magazineApi
      .getApiRequest(path, role, page)
      .then((response) => {
        dispatch(MagazineModalSuccess(response.data.info));
        dispatch(stopLoading());
        dispatch(showMessageSuccess("Fetched Successfully"));
      })
      .catch((err) => {
        dispatch(MagazineModalError(err));
        dispatch(stopLoading());
        dispatch(showMessageError(err.response));
      });
  };
};

const MagazineArchiveModalError = (err) => {
  return {
    type: magazineConstants.GET_MAGAZINE_ARCHIVE_DATA_FAILURE,
    magazineDataError: err,
  };
};

const MagazineArchiveModalSuccess = (data) => {
  return {
    type: magazineConstants.GET_MAGAZINE_ARCHIVE_DATA_SUCCESS,
    magazineData: data,
  };
};

const getMagazineArchiveModalData = () => {
  return (dispatch) => {
    dispatch(startLoading());
    let path = routesConstants.magazine.archive;
    magazineApi
      .getApiRequest(path)
      .then((response) => {
        dispatch(MagazineArchiveModalSuccess(response.data.info));
        dispatch(stopLoading());
        dispatch(showMessageSuccess("Fetched Successfully"));
      })
      .catch((err) => {
        dispatch(MagazineArchiveModalError(err));
        dispatch(stopLoading());
        dispatch(showMessageError(err?.response));
      });
  };
};

const MagazineByYearModalError = (err) => {
  return {
    type: magazineConstants.GET_MAGAZINE_DATA_BY_YEAR_FAILURE,
    magazineDataError: err,
  };
};

const MagazineByYearModalSuccess = (data) => {
  return {
    type: magazineConstants.GET_MAGAZINE_DATA_BY_YEAR_SUCCESS,
    magazineData: data,
  };
};

const getMagazineByYearModalData = (year) => {
  return (dispatch) => {
    dispatch(startLoading());
    let path = routesConstants.magazine.getByYear;
    magazineApi
      .getDataRequest(path, year)
      .then((response) => {
        dispatch(MagazineByYearModalSuccess(response.data.info));
        dispatch(stopLoading());
        dispatch(showMessageMagazineSuccess("Fetched Successfully"));
      })
      .catch((err) => {
        dispatch(MagazineByYearModalError(err));
        dispatch(stopLoading());
        dispatch(showMessageMagazineError(err?.response));
      });
  };
};

const MagazineByIdModalError = (err) => {
  return {
    type: magazineConstants.GET_MAGAZINE_DATA_BY_ID_FAILURE,
    magazineDataError: err,
  };
};

const MagazineByIdModalSuccess = (data) => {
  return {
    type: magazineConstants.GET_MAGAZINE_DATA_BY_ID_SUCCESS,
    magazineData: data,
  };
};

const getMagazineByIdModalData = (id, role) => {
  return (dispatch) => {
    dispatch(startLoading());
    let path = routesConstants.magazine.getById;
    magazineApi
      .getDataRequest(path, id, role)
      .then((response) => {
        dispatch(MagazineByIdModalSuccess(response.data.info));
        dispatch(stopLoading());
        dispatch(showMessageSuccess("Fetched Successfully"));
      })
      .catch((err) => {
        dispatch(MagazineByIdModalError(err));
        dispatch(stopLoading());
        dispatch(showMessageError(err.response));
      });
  };
};

export const magazineAction = {
  getMagazineModalData,
  getMagazineArchiveModalData,
  getMagazineByYearModalData,
  getMagazineByIdModalData,
};
