import React from "react";
import pdf from "../../Assets/CoverStories/Visionary_Vogues_page1.jpg";

function HomeStatic() {
  return (
    <div>
      <img src={pdf} width={"100%"} height={"Auto"} alt="Cover" />
    </div>
  );
}
export { HomeStatic };
