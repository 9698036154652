import React, { useEffect } from "react";
import { Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styles from "../Styles/specialdesc.module.css";
import { specialAction } from "../state/actions";
import Spinner from "../../Spinner/Spinner";
import { ProfileDetails } from "./ProfileDetails";
import { Helmet } from "react-helmet-async";

const { Content } = Layout;

const SpecialProfileDescription = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const { specialdataDesc } = useSelector((state) => state.specialReducer);
  const { isLoading } = useSelector((state) => state.loadingReducer);
  useEffect(() => {
    dispatch(specialAction.getSpecialProfileDataById(params.id, "user"));
  }, [dispatch, params.id]);

  if (isLoading) {
    return <Spinner />;
  }
  if (
    Object.keys(specialdataDesc).length === 0 &&
    specialdataDesc.constructor === Object
  ) {
    return <Spinner />;
  } else
    return (
      <Content className={styles.content}>
        <Helmet>
          <meta name="keywords" content={specialdataDesc?.keywords}></meta>
          <title>{`${specialdataDesc?.title} | Visionary Vogues`}</title>
          <meta name="description" content={specialdataDesc?.metaDescription ? specialdataDesc?.metaDescription : "Visionary Vogue: Where Business Transformation Takes Center Stage"} />

          <meta name="twitter:card" content={"type"} />
          <meta name="twitter:title" content={`${specialdataDesc?.title} | Visionary Vogues`} />
          <meta name="twitter:description" content={specialdataDesc?.metaDescription ? specialdataDesc?.metaDescription : "Visionary Vogue: Where Business Transformation Takes Center Stage"} />

          <meta property="og:image" content={specialdataDesc?.thumbnail} />
          <meta property="og:description" content={specialdataDesc?.metaDescription ? specialdataDesc?.metaDescription : "Visionary Vogue: Where Business Transformation Takes Center Stage"} />
          <meta property="og:title" content={`${specialdataDesc?.title} | Visionary Vogues`} />
          <meta property="og:type" content="website" />
          <meta
            property="og:URL"
            content={`https://visionaryvogues.com/special-profile/${specialdataDesc?.redirectURL}`}
          />

          {/* <link
          rel="canonical"
          href={`https://visionaryvogues.com/magazine/${magazinePDF._id}`}
        /> */}
        </Helmet>
        <ProfileDetails
          image={specialdataDesc?.poster || specialdataDesc?.thumbnail}
          title={specialdataDesc?.title}
          description={specialdataDesc?.description}
          keywords={specialdataDesc?.keywords}
          id={specialdataDesc?.redirectURL}
        />
      </Content>
    );
};

export default SpecialProfileDescription;
