import React from "react";
import { Typography } from "antd";
import styles from "../Styles/mainSection.module.css";
import { useNavigate } from "react-router-dom";

const MainSection = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.firstSection}>
      <div className={styles.image}>
        <Typography.Text className={styles.smalltitle}>
          VISIONARY VOGUES MAGAZINE
        </Typography.Text>
        <div className={styles.largeTitle}>
          THE MAGAZINE FOR
          <br /> LEADERS
        </div>
        <Typography.Paragraph className={styles.paragraph}>
          Visionary Vogues is where the chronicles of business evolution unfold.
          We are a leading online magazine dedicated to celebrating the visionaries,
          disruptors, and transformative leaders who reshape entire industries. 
           Our mission is to deliver a unique blend of in-depth storytelling,
          insightful analysis, and actionable advice. We illuminate the strategies,
           innovations, and mindsets that drive groundbreaking success,
            empowering readers to unlock their transformative potential.
        </Typography.Paragraph>
        <button className={styles.btn} onClick={() => navigate("/aboutus")}>
          About VV Magazine
        </button>
      </div>
    </div>
  );
};

export default MainSection;
