import { dashboardConstants } from "./action-types";

const initialState = {
  newEntry: {},
  newEntryLoading: true,
  newEntryError: null,
  error: null,
  entries: [],
  totalCount: 0,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    //get content by Tag
    case dashboardConstants.GET_BY_TAG_CONTENT_SUCCESS: {
      return {
        ...state,
        entries: action.newEntry?.info?.docs,
        totalCount: action.newEntry?.info?.totalDocs,
        newEntryLoading: true,
        newEntryError: null,
      };
    }
    case dashboardConstants.GET_BY_TAG_CONTENT_FAILURE:
      return {
        ...state,
        entries: [],
        newEntryLoading: false,
        newEntryError: action.newEntryError,
      };

    //get content by Id
    case dashboardConstants.GET_CONTENT_BY_ID_SUCCESS: {
      return {
        ...state,
        newEntry: action.newEntry,
        newEntryLoading: true,
        newEntryError: null,
      };
    }
    case dashboardConstants.GET_CONTENT_BY_ID_FAILURE:
      return {
        ...state,
        newEntry: [],
        newEntryLoading: false,
        newEntryError: action.newEntryError,
      };

    case dashboardConstants.CONTENT_CREATION_SUCCESS: {
      return {
        ...state,
        newEntry: [...state.newEntry, action.newEntry],
        newEntryLoading: true,
        newEntryError: null,
      };
    }
    case dashboardConstants.CONTENT_CREATION_FAILURE:
      return {
        ...state,
        newEntry: [],
        newEntryLoading: false,
        error: action.newEntryError,
      };

    case dashboardConstants.UP_TRENDING_CREATION_SUCCESS: {
      return {
        ...state,
        newEntry: [...state.newEntry, action.newEntry],
        newEntryLoading: true,
        newEntryError: null,
      };
    }
    case dashboardConstants.UP_TRENDING_CREATION_FAILURE:
      return {
        ...state,
        newEntry: [],
        newEntryLoading: false,
        error: action.newEntryError,
      };

    // update Event
    case dashboardConstants.CONTENT_UPDATE_SUCCESS: {
      const index = state.newEntry.findIndex((el) => el._id === action.id);
      // Create the updated entry object
      const updatedEntry = {
        ...state.newEntry[index],
        title: action.newEntry.title,
        tag: action.newEntry.tag,
        description: action.newEntry.description,
        active: action.newEntry.active,
        isDeleted: action.newEntry.isDeleted,
      };

      // Create a new array with the updated entry
      const updatedNewEntry = [...state.newEntry];
      updatedNewEntry[index] = updatedEntry;

      return {
        ...state,
        newEntry: updatedNewEntry,
        newEntryLoading: true,
        newEntryError: null,
      };
    }
    case dashboardConstants.CONTENT_UPDATE_FAILURE:
      return {
        ...state,
        newEntry: [],
        newEntryLoading: false,
        error: action.newEntryError,
      };

    // delete Event
    case dashboardConstants.DELETE_CONTENT_BY_ID_SUCCESS: {
      const updatedEntry = state.newEntry.filter((blog) => {
        return blog._id !== action.blog._id;
      });

      return {
        ...state,
        newEntry: updatedEntry,
        newEntryLoading: true,
        newEntryError: null,
      };
    }
    case dashboardConstants.DELETE_CONTENT_BY_ID_FAILURE:
      return {
        ...state,
        newEntry: [],
        newEntryLoading: false,
        newEntryError: action.newEntryError,
      };
    case dashboardConstants.CLEAR_NEWENTRY:
      return {
        ...state,
        newEntry: {},
      };
    case dashboardConstants.CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export { dashboardReducer };
