export const magazineConstants = {
  GET_MAGAZINE_DATA_FAILURE: "GET_MAGAZINE_DATA_FAILURE",
  GET_MAGAZINE_DATA_SUCCESS: "GET_MAGAZINE_DATA_SUCCESS",

  GET_MAGAZINE_DATA_BY_ID_FAILURE: "GET_MAGAZINE_DATA_BY_ID_FAILURE",
  GET_MAGAZINE_DATA_BY_ID_SUCCESS: "GET_MAGAZINE_DATA_BY_ID_SUCCESS",

  GET_MAGAZINE_ARCHIVE_DATA_FAILURE: "GET_MAGAZINE_ARCHIVE_DATA_FAILURE",
  GET_MAGAZINE_ARCHIVE_DATA_SUCCESS: "GET_MAGAZINE_ARCHIVE_DATA_SUCCESS",

  GET_MAGAZINE_DATA_BY_YEAR_FAILURE: "GET_MAGAZINE_DATA_BY_YEAR_FAILURE",
  GET_MAGAZINE_DATA_BY_YEAR_SUCCESS: "GET_MAGAZINE_DATA_BY_YEAR_SUCCESS",
};
