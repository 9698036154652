import React, { useState } from "react";
import { Col, Empty, Layout, Row, Typography } from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { v4 as uuidv4 } from "uuid";
import styles from "../Styles/upTrending.module.css";
import VerticalCard from "../../Common/Components/VerticalCard";

const { Content } = Layout;
const { Title } = Typography;

const UpTrending = ({ upTrending, related,alignment }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow:
      upTrending && upTrending.length >= 3
        ? 3
        : upTrending && upTrending.length >= 2
        ? 2
        : 1,
    centerMode: true,
    autoplay: true,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    focusOnChange: true,
    useCSS: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: upTrending && upTrending.length >= 2 ? 2 : 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: upTrending && upTrending.length >= 2 ? 2 : 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          arrows: false,
          fade: true,
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <Content className={styles.dailyScoopContent}>
      <Row style={{ backgroundColor: "#005293" }}>
        <div className={styles.titleWrapper}>
          <Title className={styles.title} style={{marginBottom:"5px"}} >
            {related ? "On The Cover" : "UP-TRENDING"}
          </Title>
        </div>
        <div className={styles.divider}></div>
        <Col span={24} style={{marginTop:"1rem"}}>
          {upTrending?.length === 0 || upTrending === undefined ? (
            <Empty
              style={{
                marginTop: "100px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ) : (
            <Slider
              {...settings}
              style={{
                padding: "15px",
              }}
            >
              {upTrending?.map((data) => (
                <React.Fragment key={uuidv4()}>
                  <VerticalCard
                    key={uuidv4()}
                    image={
                      alignment === "poster"
                        ? data?.poster
                        : data?.thumbnail
                    }
                    title={data?.title}
                    carousel={true}
                    textType={2}
                    uptrendingpath={data?.redirectURL}
                   
                  />
                </React.Fragment>
              ))}
            </Slider>
          )}
        </Col>
      </Row>
    </Content>
  );
};

export default UpTrending;
