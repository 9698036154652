import { upTrendingConstants } from "./action-types";
import { commonApi } from "../../Common/utils/api";
import {
  showMessageError,
  showMessageIdError,
  showMessageIdSuccess,
  showMessageSuccess,
  startLoading,
  stopLoading,
} from "../../state/actions";

const getUpTrendingError = (err) => {
  return {
    type: upTrendingConstants.GET_UP_TRENDING_FAILURE,
    newEntryError: err,
  };
};

const getUpTrendingSuccess = (data) => {
  return {
    type: upTrendingConstants.GET_UP_TRENDING_SUCCESS,
    upTrending: data,
  };
};

const getUpTrendingData = (role, page) => {
  return (dispatch) => {
    dispatch(startLoading());
    commonApi
      .getUpTrendingRequest(role, page)
      .then((response) => {
        dispatch(getUpTrendingSuccess(response.data.info));
        dispatch(stopLoading());
        dispatch(showMessageSuccess("Fetched Successfully"));
      })
      .catch((err) => {
        dispatch(getUpTrendingError(err));
        dispatch(stopLoading());
        dispatch(showMessageError(err?.response));
      });
  };
};

const getUpTrendingByIdError = (err) => {
  return {
    type: upTrendingConstants.GET_UP_TRENDING_BY_ID_FAILURE,
    newEntryError: err,
  };
};

const getUpTrendingByIdSuccess = (data) => {
  return {
    type: upTrendingConstants.GET_UP_TRENDING_BY_ID_SUCCESS,
    upTrending: data,
  };
};

const getUpTrendingDataById = (id, role, page) => {
  return (dispatch) => {
    dispatch(startLoading());
    commonApi
      .getUpTrendingById(id, role, page)
      .then((response) => {
        dispatch(getUpTrendingByIdSuccess(response.data.info));
        dispatch(stopLoading());
        dispatch(showMessageIdSuccess("Fetched Successfully"));
      })
      .catch((err) => {
        dispatch(getUpTrendingByIdError(err));
        dispatch(stopLoading());
        dispatch(showMessageIdError(err?.response));
      });
  };
};

export const upTrendingAction = {
  getUpTrendingData,
  getUpTrendingDataById,
};
