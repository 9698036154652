import React from "react";
import { Col, Image, Row } from "antd";
import { Helmet } from "react-helmet-async";
import styles from "../Styles/specialdesc.module.css";

function ProfileDetails({ image, title, description, keywords, id }) {
  let getConten = (data) => {
    if (data) {
      const doc = new DOMParser().parseFromString(
        data.description,
        "text/html"
      );

      const divs = doc.querySelectorAll("div");
      let extractedTexts = [];

      for (let i = 0; i < divs.length; i++) {
        const hasImg = divs[i].querySelector("img");
        if (!hasImg) {
          extractedTexts.push(divs[i].textContent.trim());
        }
      }
      let combinedText = extractedTexts.slice(0, 3).join(" ");
      return combinedText;
    }
    return "Thanks";
  };
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="keywords" content={keywords}></meta>
        <meta name="description" content={getConten(description)} />

        <meta name="twitter:card" content={"type"} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={getConten(description)} />

        <meta property="og:image" content={image} />
        <meta property="og:description" content={getConten(description)} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:URL"
          content={`https://visionaryvogues.com/special-profile/${id}`}
        />
      </Helmet>
      <Row gutter={[16, 16]} className={styles.rowContent}>
        <Col xs={2} sm={2} md={2} lg={3} xl={3} xxl={3}></Col>
        <Col xs={24} sm={24} md={10} lg={9} xl={9} xxl={9}>
          <div className={styles.posterContainer}>
            <Image
              preview={false}
              src={image}
              width={"100%"}
              height={"auto"}
              className={styles.poster}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={10} lg={9} xl={9} xxl={9}>
          <div className={styles.textWrapper}>
            <p
              style={{
                color: "#fff",
                marginLeft: "13px",
                textAlign: "left",
                alignSelf: "flex-start",
                marginTop: "5px",
              }}
              className={styles.title}
            >
              {title}
            </p>
          </div>
        </Col>
        <Col xs={2} sm={2} md={2} lg={3} xl={3} xxl={3}></Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={3}></Col>
        <Col span={18}>
          <div
            className={styles.descImg}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Col>
        <Col span={3}></Col>
      </Row>
    </div>
  );
}
export { ProfileDetails };
