import { Button, Result } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const ErrorHandling = ({ code, statusText }) => {
  const navigate = useNavigate();
  return (
    <>
      <Result
        // status={code===400?"404":code}
        // title={code}
        subTitle="No data"
        extra={
          <Button type="primary" onClick={() => navigate(-1)}>
            Back
          </Button>
        }
      />
    </>
  );
};

export { ErrorHandling };
