import React from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Layout,
  Row,
  Typography,
  Upload,
  message,
} from "antd";
import sponsoredImage from "../Assets/sponsoredPost/sponsored_post_image.png";
import styles from "./styles/sponsoredPost.module.css";
import contactStyles from "./styles/Form.module.css";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { contactUsAction } from "./state/actions";
import { UploadOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet-async";
import { constants } from "../Common/Components/Constants";

const { Content } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;

const SponsoredPost = () => {
  const dispatch = useDispatch();

  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [secondform] = Form.useForm();

  const handleSubmit = (values) => {
    dispatch(contactUsAction.AddContactUsModalData(values, "enquire"));
    form.resetFields();
    messageApi.open({
      type: "success",
      content: "Enquiry submitted successfully",
    });
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList[0]?.originFileObj;
  };

  const handleSubmitSponsored = (formvalues) => {
    dispatch(contactUsAction.AddContactUsModalData(formvalues, "sponsored"));
    secondform.resetFields();
    messageApi.open({
      type: "success",
      content: "Guest post submitted successfully",
    });
  };

  const isTab = useMediaQuery({ maxWidth: 992 });
  return (
    <Content style={{ overflowX: "hidden" }}>
      <Helmet>
        <meta name="keywords" content={constants.sponsoredGuestPost.title}></meta>
        <title>{constants.sponsoredGuestPost.title}</title>
        <meta name="description" content={constants.sponsoredGuestPost.description} />

        <meta property="og:title" content={constants.sponsoredGuestPost.title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:URL"
          content={`https://visionaryvogues.com/sponsored-post`}
        />
      </Helmet>
      {contextHolder}

      <div className={styles.textStyle}>
        <Row
          gutter={[32, 16]}
          style={{ justifyContent: "center", alignItems: "flex-start" }}
        >
          <Col xs={21} sm={20} md={20} lg={12} xl={12}>
            <Title className={styles.sponsoredTitle}>Submit Guest Posts</Title>
            <Text className={contactStyles.text}>
              Visionary Vogues Magazine allows you to submit your guest posts in
              its website and share it with it’s ever-growing community of
              readers and clients. We encourage experts and bloggers to
              contribute to our platform with their high-quality content. We
              accept guest posts from a wide-variety of categories and niches.
              Furthermore, our team’s fast turnaround time makes it one of the
              most attractive options for writers and outreach brands.
            </Text>
          </Col>
          <Col xs={21} sm={20} md={20} lg={9} xl={9}>
            <Form
              form={form}
              layout="vertical"
              style={{ textAlign: "left" }}
              onFinish={(formvalues) => handleSubmit(formvalues)}
            >
              <Form.Item name={"name"}>
                <Input
                  className={styles.formInput}
                  placeholder="Your name"
                  type="text"
                />
              </Form.Item>
              <Form.Item
                name="email"
                required
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input placeholder="@email.com" className={styles.formInput} />
              </Form.Item>
              <Form.Item name={"company"}>
                <Input
                  placeholder="Company Name"
                  className={styles.formInput}
                />
              </Form.Item>
              <Form.Item name={"sponsoredPostDetails"}>
                <TextArea
                  rows={4}
                  placeholder="Guest Details"
                  className={styles.formInput}
                />
              </Form.Item>
              <div style={{ textAlign: "end", paddingTop: "10px" }}>
                <Button htmlType="submit" className={contactStyles.sendBtn}>
                  Enquire Details
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
      <Row
        gutter={[16, 16]}
        style={{ padding: isTab ? "10px" : "30px", overflowX: "hidden" }}
      >
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={12}
          order={isTab ? 2 : 1}
        >
          <div className={styles.sponsoredPostTextWrapper}>
            <Title
              style={{
                display: "block",
                marginLeft: "0",
                color: "black",
                fontSize: "2.2em",
              }}
            >
              Submit Guest Posts to Visionary Vogues Magazine
            </Title>
            <Text style={{ display: "block" }} className={styles.sponsoredText}>
              By submitting your guest posts to Visionary Vogues Magazine, you're
              able to reach out to a wide audience that consumes our content on
              a daily basis. Our platform enables you to market your product and
              services in a creative and articulate manner.
            </Text>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={12}
          order={isTab ? 1 : 2}
        >
          <Image src={sponsoredImage} preview={false} />
        </Col>
      </Row>

      {/* Form section */}
      <div className={styles.formSection}>
        <div className={styles.formSection_textWrapper}>
          <Text
            style={{ display: "block" }}
            className={styles.formSection_textBold}
          >
            submit your guest post
          </Text>
          <Text
            style={{ display: "block" }}
            className={styles.formSection_text}
          >
            Fill up the below form with the details of your guest post and we
            will revert back to you with the pricing. In case you want to
            publish multiple guest posts, the pricing will be adjusted
            accordingly. All payments will be accepted only via PayPal. Make
            sure that the guest posts are not spammy and adult-related content.
            We accept various niche of articles, especially ranging from
            business to tech. Check out our blogs here.
          </Text>
        </div>

        <Row gutter={[16, 16]} justify={"center"}>
          <Col
            style={{ width: "100%" }}
            xs={24}
            sm={24}
            md={24}
            lg={18}
            xl={18}
            xxl={18}
          >
            <Form
              layout="vertical"
              form={secondform}
              onFinish={(formvalues) => handleSubmitSponsored(formvalues)}
            >
              <Form.Item
                name="name"
                wrapperCol={24}
                style={{ cursor: "pointer" }}
                required
                rules={[{ required: true }]}
              >
                <Input
                  className={styles.formInput}
                  placeholder="Your name"
                  type="text"
                />
              </Form.Item>
              <Form.Item
                name="email"
                required
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input placeholder="@email.com" className={styles.formInput} />
              </Form.Item>
              <Form.Item
                name="website"
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                  {
                    type: "url",
                    message: "This field must be a valid url.",
                  },
                ]}
              >
                <Input className={styles.formInput} placeholder="Website" />
              </Form.Item>

              <Form.Item name="message" rules={[{ required: true }]}>
                <TextArea
                  rows={4}
                  className={styles.formInput}
                  placeholder="Message"
                />
              </Form.Item>
              <Form.Item
                name={"file"}
                valuePropName="file"
                getValueFromEvent={normFile}
              >
                <Upload
                  maxCount={1}
                  beforeUpload={() => false}
                  onChange={(info) => {
                    // const { fileList } = info;
                  }}
                >
                  <Button
                    style={{ width: "fit-content" }}
                    icon={<UploadOutlined />}
                  >
                    Choose File
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item
                rules={[{ required: true }]}
                style={{ textAlign: "left" }}
              >
                <Checkbox className={styles.formInputCheckbox}>
                  Tick this box to prove you're not a robot.
                </Checkbox>
              </Form.Item>
              <div className={styles.buttonWrapper}>
                <button type="submit">Submit</button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export { SponsoredPost };
