import { Tag, Typography } from "antd";
import React from "react";
import {
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  RedditSquareFilled,
  TwitterSquareFilled,
  WhatsAppOutlined,
} from "@ant-design/icons";
import styles from "../../DailyScop/Styles/dailyScoopDescription.module.css";

const { Text } = Typography;

function SocialShare({ url, content }) {
  let encodedURLWhatsAppmsg = encodeURIComponent(content);
  let encodedURLAppurl = encodeURIComponent(url);

  const getURLContent = {
    Reddit: `https://www.reddit.com/submit?url=${encodedURLAppurl}&title=${encodedURLWhatsAppmsg}`,
    LinkedIn: `https://www.linkedin.com/sharing/share-offsite/?url=${encodedURLAppurl}`,
    Facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodedURLAppurl}`,
    Instagram: `https://www.instagram.com/`,
    WhatsApp: `https://wa.me/?text=${encodedURLWhatsAppmsg}%0A${encodedURLAppurl}`,
    Twitter: `https://twitter.com/intent/tweet?url=${encodedURLAppurl}&text=${encodedURLWhatsAppmsg}!`,
  };

  let shareEventHandler = (socialSite) => {
    window.open(getURLContent[socialSite], "_blank");
  };
  return (
    <div className={styles.socialaccounts}>
      <Text className={styles.sharePost}>Share Post : &nbsp;&nbsp;</Text>
      <Tag
        style={{ marginTop: "10px", cursor: "pointer" }}
        icon={<TwitterSquareFilled />}
        color="#55acee"
        onClick={() => shareEventHandler("Twitter")}
      >
        Twitter
      </Tag>
      <Tag
        style={{ marginTop: "10px", cursor: "pointer" }}
        icon={<InstagramFilled />}
        color="#fbad50"
        onClick={() => shareEventHandler("Instagram")}
      >
        Instagram
      </Tag>
      <Tag
        style={{ marginTop: "10px", cursor: "pointer" }}
        icon={<FacebookFilled />}
        color="#3b5999"
        onClick={() => shareEventHandler("Facebook")}
      >
        Facebook
      </Tag>
      <Tag
        style={{ marginTop: "10px", cursor: "pointer" }}
        icon={<LinkedinFilled />}
        color="#55acee"
        onClick={() => shareEventHandler("LinkedIn")}
      >
        LinkedIn
      </Tag>
      <Tag
        style={{ marginTop: "10px", cursor: "pointer" }}
        icon={<RedditSquareFilled />}
        color="RGBA(255, 86, 0, 1)"
        onClick={() => shareEventHandler("Reddit")}
      >
        Reddit
      </Tag>
      <Tag
        style={{ marginTop: "10px", cursor: "pointer" }}
        icon={<WhatsAppOutlined />}
        color="#0cc144"
        onClick={() => shareEventHandler("WhatsApp")}
      >
        WhatsApp
      </Tag>
    </div>
  );
}
export { SocialShare };
