import { navbarConstants } from "./action-types";
import { searchApi } from "../utils/api";
import { routesConstants } from "../../Common/utils/allroutes";
import { startLoadingSearch, stopLoadingSearch } from "../../state/actions";

/**
 * To update Navbar title action
 * @param {string}
 */

const SearchModalError = (err) => {
  return {
    type: navbarConstants.GET_SEARCH_RESULTS_FAILURE,
    searchDataError: err,
  };
};

const SearchModalSuccess = (data) => {
  return {
    type: navbarConstants.GET_SEARCH_RESULTS_SUCCESS,
    searchData: data,
  };
};

const getSearchData = (search, role) => {
  return (dispatch) => {
    dispatch(startLoadingSearch())
    let path = routesConstants.search.get;
    searchApi
      .getDataRequest(path, search, role)
      .then((response) => {
        dispatch(SearchModalSuccess(response.data.info));
        dispatch(stopLoadingSearch())
      })
      .catch((err) => {
        dispatch(SearchModalError(err));
        dispatch(stopLoadingSearch())
      });
  };
};

const searchReset = () => {
  return {
    type: navbarConstants.SEARCH_RESET,
  };
};

export const navbarAction = {
  getSearchData,
  searchReset,
};
