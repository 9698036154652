import React from "react";
import { Typography } from "antd";
import { v4 as uuidv4 } from "uuid";
import termsOfUseBanner from "../Assets/static/terms.png";
import styles from "./Styles/static.module.css";
import { Helmet } from "react-helmet-async";
import { constants } from "../Common/Components/Constants";
const { Title, Text } = Typography;

const TermsOfUse = () => {
  const termsConditions = [
    "The website visitor must be at least 18 years old, in order to use Visionary Vogues Website. (www.visionaryvogues.com)",
    "Visionary Vogues hereby grants you the permission to view this website and to use it purely for general informational and non-commercial purposes.",
    "Visionary Vogues owns intellectual property rights for the content, designs, processes, models, products, software or ideas described in this website.",
    "Distribution, Changes, Reuse/ Repost of our content without appropriate credits to Visionary Vogues is strictly prohibited. One may always share, distribute, and post our intellectual property by giving proper credits to us.",
    "This site (www.visionaryvogues.com) contains several links pointing to other websites. Visionary Vogues will not be responsible for the content or for any consequences of visiting sites that are linked to this site.",
    "The Information provided by Visionary Vogues, through emails is exclusively intended for the addressee. Use of the information by any party other than the addressee is prohibited.",
    "All the content on this website is published for basic information purpose only. Visionary Vogues (www.visionaryvogues.com) does not make any conclusions or warranties about the reliability and accuracy of this information. Any action taken by you upon the information you find on this website, is strictly at your own risk. Visionary Vogues will not be responsible for any losses and/or damages in continuation with the use of our website.",
    "You can visit other websites from our website by following hyperlinks. We don’t have any kind of control on the content, images and posts published on those websites. We do ensure to provide quality content to our website and constantly check for such links. If we find any suspicious link, it will be removed immediately, without any notice given.",
    "When you leave this site to next site, it has different privacy policies and terms of use, for which Visionary Vogues is not responsible.",
    "The content that is shared on this website, or other social media platforms including, but not only, Facebook, LinkedIn, Twitter, Pinterest, Tumblr, Google+ etc. are views of individual blogger, writer or it’s source. Visionary Vogues does not necessarily approve their opinions.",
    "We periodically check content of comments on different social media platforms. If we find any abusive, misleading, vulgar and negative content, such comments or sentences will be permanently deleted without informing the writer.",
  ];
  return (
    <>
      <Helmet>
        <meta name="keywords" content={constants.termsOfUse.title}></meta>
        <title>{constants.termsOfUse.title}</title>
        <meta name="description" content={constants.termsOfUse.description} />
        <meta property="og:title" content={constants.termsOfUse.title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:URL"
          content={`https://visionaryvogues.com/terms-of-use`}
        />
      </Helmet>
      <section>
        <img
          src={termsOfUseBanner}
          alt="Terms_of_use"
          className={styles.image}
        />
        <div className={styles.overlay}>
          <Title
            style={{ display: "block", marginBottom: "3px", color: "white" }}
            className={styles.title}
          >
            Terms of use
          </Title>
          <Text style={{ display: "block" }} className={styles.text}>
            Accessing our site implies agreement to our terms of use
          </Text>
        </div>
      </section>

      <div className={styles.container}>
        <ol
          style={{
            marginTop: "40px",
          }}
        >
          {termsConditions?.map((condition) => (
            <li key={uuidv4()} style={{ marginBottom: "10px" }}>
              <Text className={styles.text}>{condition}</Text>
            </li>
          ))}
        </ol>
        <Text style={{ display: "block" }} className={styles.consentOfReader}>
          Consent of Reader
        </Text>
        <Text className={styles.text1}>
          By using our website, you hereby agree to our disclaimer, terms and
          policies.
        </Text>
      </div>
    </>
  );
};

export { TermsOfUse };
