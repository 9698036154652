import { specialProfileConstants } from "./action-types";
import { commonApi } from "../../Common/utils/api";
import {
  showMessageError,
  showMessageIdError,
  showMessageIdSuccess,
  showMessageSuccess,
  startLoading,
  stopLoading,
} from "../../state/actions";

const getSpecialError = (err) => {
  return {
    type: specialProfileConstants.GET_SPECIAL_FAILURE,
    newEntryError: err,
  };
};

const getSpecialSuccess = (data) => {
  return {
    type: specialProfileConstants.GET_SPECIAL_SUCCESS,
    special: data,
  };
};

const getSpecialProfiledata = (role, page) => {
  return (dispatch) => {
    dispatch(startLoading());
    commonApi
      .getSpecialProfileData(role, page)
      .then((response) => {
        dispatch(getSpecialSuccess(response.data.info));
        dispatch(stopLoading());
        dispatch(showMessageSuccess("Fetched Successfully"));
      })
      .catch((err) => {
        dispatch(getSpecialError(err));
        dispatch(stopLoading());
        dispatch(showMessageError(err?.response));
      });
  };
};

const getSpecialProfileByIdError = (err) => {
  return {
    type: specialProfileConstants.GET_SPECIAL_BY_ID_FAILURE,
    newEntryError: err,
  };
};

const getSpecialProfileByIdSuccess = (data) => {
  return {
    type: specialProfileConstants.GET_SPECIAL_BY_ID_SUCCESS,
    special: data,
  };
};

const getSpecialProfileDataById = (id, role) => {
  return (dispatch) => {
    dispatch(startLoading());
    commonApi
      .getSpecialById(id, role)
      .then((response) => {
        dispatch(getSpecialProfileByIdSuccess(response.data.info));
        dispatch(stopLoading());
        dispatch(showMessageIdSuccess("Fetched Successfully"));
      })
      .catch((err) => {
        dispatch(getSpecialProfileByIdError(err));
        dispatch(stopLoading());
        dispatch(showMessageIdError(err?.response));
      });
  };
};

export const specialAction = {
  getSpecialProfiledata,
  getSpecialProfileDataById,
};
