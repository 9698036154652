import {
  applyMiddleware,
  legacy_createStore as createStore,
  combineReducers,
} from "redux";
import { thunk } from "redux-thunk";
import { homeReducer } from "./Home";
import { descriptionListingReducer } from "./DescriptinListing";
import { appReducer } from "./App";
import { addContentReducer } from "./AddContent";
import { dashboardReducer } from "./Dashboard";
import { dailyScoopReducer } from "./DailyScop";
import { upTrendingReducer } from "./UpTrending";
import { magazineReducer } from "./Magazines";
import { loadingReducer } from "./state";
import { contactUsReducer } from "./ContactForm";
import { womenLeadersAndCoverStoriesReducer } from "./WomenLeaders";
import { navbarReducer } from "./Navbar";
import { specialReducer } from "./SpecialProfile/state/reducer";
import { commonReducer } from "./Common";
const reducers = {
  homeReducer,
  descriptionListingReducer,
  appReducer,
  addContentReducer,
  dashboardReducer,
  dailyScoopReducer,
  upTrendingReducer,
  magazineReducer,
  loadingReducer,
  contactUsReducer,
  womenLeadersAndCoverStoriesReducer,
  navbarReducer,
  specialReducer,
  commonReducer
};

const rootReducers = combineReducers(reducers);
const store = applyMiddleware(thunk)(createStore)(rootReducers);

export { store, reducers };
