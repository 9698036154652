import React from "react";
const FlipBook = ({ pdf }) => {
  return (
    <div
      id="flipId"
      style={{ overflow: "hidden", background: "rgb(192,217,240)" }}
    >
      <div dangerouslySetInnerHTML={{ __html: pdf }} />
    </div>
  );
};

export { FlipBook };
