import { startLoading, stopLoading } from "../../state/actions";
import { commonApi } from "../utils/api";
import { commonConstants } from "./action-types";


const getArticleBasedonURLError = (err) => {
    return {
        type: commonConstants.GET_ARTICLE_BY_REDIRECT_URL_FAILURE,
        newEntryError: err,
    };
};

const getArticleBasedonURLSuccess = (data) => {
    return {
        type: commonConstants.GET_ARTICLE_BY_REDIRECT_URL_SUCCESS,
        blogs: data,
    };
};

const getArticleBasedonURL = (redirectURL,role) => {
    return (dispatch) => {
        dispatch(startLoading());

        commonApi.getArticleByRedirectURL(redirectURL,role)
            .then((response) => {
                dispatch(getArticleBasedonURLSuccess(response.data.info));
                dispatch(stopLoading());
            })
            .catch((err) => {
                dispatch(getArticleBasedonURLError(err));
                dispatch(stopLoading());
            });
    };
};

export const clearArticle = () => ({
    type: commonConstants.CLEAR_ARTICLE,
  });

export const commonAction = {
    getArticleBasedonURL,
    clearArticle
};
