import { loginConstants } from "./action-types";

const initialState = {
  LoginData: false,
  LoginDataLoading: true,
  LoginError: null,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginConstants.LOGIN_SUCCESS:
      localStorage.setItem("auth-token", action.LoginData.token);
      return {
        ...state,
        LoginData: action.LoginData,
        LoginDataLoading: true,
        LoginError: null,
      };
    case loginConstants.LOGIN_FAILURE:
      return {
        ...state,
        LoginData: false,
        LoginDataLoading: false,
        LoginError: action.LoginError,
      };
    case loginConstants.LOGOUT_SUCCESS:
      return {
        ...state,
        LoginData: action.LoginData,
        LoginDataLoading: true,
        LoginError: null,
      };
    case loginConstants.LOGOUT_FAILURE:
      return {
        ...state,
        LoginData: [],
        LoginDataLoading: false,
        LoginError: action.LoginError,
      };

    default:
      return state;
  }
};
export { appReducer };
