import React from "react";
import { Image, Typography } from "antd";
import { Link } from "react-router-dom";
import { fallback } from "../utils/faultTolerant";
import styles from "../Styles/verticalCard.module.css";
const { Text, Paragraph } = Typography;

const VerticalCard = ({
  id,
  image,
  tag,
  title,
  type,
  path,
  textType,
  magazin,
  subPath,
  uptrendingpath,
  onthecover,
}) => {
  return (
    <div
      className={`${styles.cardType1}`}
      style={{
        position: tag ? "relative" : "initial",
      }}
    >
      <div>
        <div style={{ marginBottom: type === "magazine" ? "0" : "22px" }}>
          {type === "magazine" ? (
            <Link
              to={`${onthecover
                ? `/magazine/${id}`
                : id
                  ? `/${id}`
                  : uptrendingpath
                    ? `/magazine/${uptrendingpath}`
                    : "/example"
                } `}
            >
              <Image
                src={image}
                alt={tag}
                preview={false}
                width={"100%"}
                height={magazin === "true" ? "100%" : "100%"}
                className={styles.cardType1Image}
                fallback={fallback}
                style={{ boxShadow: "0px 0px 5px grey" }}
              />
            </Link>
          ) : id ? (
            <Link
              style={{ color: textType === 2 ? "white" : "black" }}
              to={`/${id}`}
              className={styles.link}
            >
              <Image
                src={image}
                alt={tag}
                preview={false}
                width={"100%"}
                height={magazin === "true" ? "100%" : "100%"}
                className={styles.cardType1Image}
                fallback={fallback}
              />
            </Link>
          ) : (
            <Link
              style={{ color: textType === 2 ? "white" : "black" }}
              to={`${path
                ? `/daily-scoop/${path}`
                : subPath
                  ? `/${subPath}`
                  : uptrendingpath
                    ? `/${uptrendingpath}`
                    : "/*"
                }`}
              className={styles.link}
            >
              <Image
                src={image}
                alt={tag}
                preview={false}
                width={"100%"}
                height={magazin === "true" ? "100%" : "100%"}
                className={styles.cardType1Image}
                fallback={fallback}
              />
            </Link>
          )}
        </div>
        {path && (
          <Link to={`/daily-scoop/${path}`}>
            {tag ? <Text className={styles.tag}>{tag}</Text> : <></>}
          </Link>
        )}
        {type === "magazine" ? (
          <Paragraph
            ellipsis={{ rows: 3, symbol: "...", width: 300 }}
            className={styles.magazineTitle}
          >
            <Link
              to={`${onthecover
                ? `/magazine/${id}`
                : id
                  ? `/${id}`
                  : "/example"
                } `}
              style={{ color: "black" }}
            >
              {title}
            </Link>
          </Paragraph>
        ) : (
          <Paragraph
            ellipsis={{ rows: 3, symbol: "..." }}
            className={textType === 2 ? styles.title2 : styles.title}
          >
            {id ? (
              <Link
                style={{ color: textType === 2 ? "white" : "black" }}
                to={`${`/${id}`}`}
                className={styles.link}
              >
                {title}
              </Link>
            ) : (
              <Link
                style={{ color: textType === 2 ? "white" : "black" }}
                to={`${path
                  ? `/daily-scoop/${path}`
                  : subPath
                    ? `/${subPath}`
                    : uptrendingpath
                      ? `/${uptrendingpath}`
                      : "/*"
                  }`}

                className={styles.link}
              >
                {title}
              </Link>
            )}
          </Paragraph>
        )}
      </div>
    </div>
  );
};

export default VerticalCard;
