import React, { useState } from "react";
import {
  Button,
  Empty,
  Image,
  Layout,
  Row,
  Typography,
  Carousel,
  Col,
} from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper/modules";
import { useMediaQuery } from "react-responsive";
import { fallback } from "../../Common/utils/faultTolerant";
import "../Styles/magazineSection.css";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";

const { Content } = Layout;
const { Title } = Typography;

const MagazineSection = ({ magazines }) => {
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const isMedium = useMediaQuery({ maxWidth: 768 });
  const isLarge = useMediaQuery({ maxWidth: 1900 });
  const [activeSlide, setActiveSlide] = useState(0);
  const navigate = useNavigate();

  return (
    <Content>
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        style={{ padding: "30px", borderBottom: "1px solid #dcdcdc" }}
        className="container"
      >
        <Title className="textStyle">Latest Magazines</Title>
        {magazines?.length === 0 || magazines === undefined ? (
          <Empty
            style={{
              marginTop: "100px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ) : isMobile ? (
          // <Row gutter={[0, 16]} justify={"center"}>
            <Col xs={24} sm={24}>
              <Carousel autoplay effect="scrollx" dots={false}>
                {magazines?.map((data) => (
                  <Image
                    src={data?.thumbnail}
                    alt="image5"
                    width={"100%"}
                    height={"100%"}
                    preview={false}
                    fallback={fallback}
                    onClick={() => navigate(`/${data?.redirectURL}`)}
                  />
                ))}
              </Carousel>
            </Col>
          // </Row>
        ) : (
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            autoplay={{
              delay: 1500,
              pauseOnMouseEnter: true,
            }}
            centeredSlides={true}
            centeredSlidesBounds={true}
            loop={true}
            slideToClickedSlide={true}
            slidesPerView={isMedium ? 2 : isLarge ? 3 : 5}
            slidesPerGroup={1}
            onClick={(e) => setActiveSlide(e.realIndex)}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
            className="swiper-container"
          >
            {(magazines?.length < 10
              ? magazines?.concat(magazines)
              : magazines
            )?.map((data, i) => (
              <SwiperSlide
                // style={{ position: "relative" }}
                key={uuidv4()}
                className={`slide-${i}`}
              >
                <div style={{ position: "relative" }}>
                  <Image
                    src={data?.thumbnail}
                    alt="image_1"
                    width={"100%"}
                    height={"auto"}
                    fallback={fallback}
                    preview={false}
                    style={{ boxShadow: "0 1px 1px 1px grey" }}
                  />
                  {activeSlide === i && (
                    <div className={"magazine"}>
                      <Link to={`/${data.redirectURL}`}>
                        <Button size="large" className="btn">
                          View Magazine
                        </Button>
                      </Link>
                    </div>
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Row>
    </Content>
  );
};

export default MagazineSection;
