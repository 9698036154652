import React from "react";
import styles from "./Styles/aboutUs.module.css";
import { Col, Image, Row, Typography } from "antd";
import whoweare from "../Assets/whoweare.png";
import transformative from "../Assets/transformative.png";
import transformative2 from "../Assets/transformative2.png";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet-async";
import { constants } from "../Common/Components/Constants";
const { Title, Text } = Typography;

const AboutUs = () => {
  const isMobile = useMediaQuery({ maxWidth: 576 });
  return (
    <div style={{ overflowX: "hidden" }}>
      {/* <Helmet>
        <meta name="keywords" content={constants.aboutUs.title}></meta>
        <title>{constants.aboutUs.title}</title>
        <meta name="description" content={constants.aboutUs.description} />
        <meta property="og:title" content={constants.aboutUs.title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:URL"
          content={`https://visionaryvogues.com/aboutus`}
        />
      </Helmet> */}
      <Helmet>
        <meta name="keywords" content={constants.aboutUs.title}></meta>
        <title>{constants.aboutUs.title}</title>
        <meta name="description" content={constants.aboutUs.description} />

        <meta name="twitter:card" content="type" />
        <meta name="twitter:title" content={constants.aboutUs.title} />
        <meta name="twitter:description" content={constants.aboutUs.description} />

        <meta property="og:title" content={constants.aboutUs.title} />
        <meta property="og:description" content={constants.aboutUs.description} />
        <meta property="og:type" content="website" />
        <meta property="og:URL" content={`https://visionaryvogues.com/aboutus`} />
      </Helmet>
      <div
        style={{
          minHeight: "9rem",
          display: "flex",
          textAlign: "center",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Title style={{ fontSize: "3.2em", fontWeight: 800 }}>About Us</Title>
      </div>
      <div className={styles.maincontent}>
        <Title style={{ fontSize: "2.2em", fontWeight: "800", color: "#fff" }}>
          Visionary Vogues: Where Business Transformation Takes Center Stage
        </Title>
        <Text className={styles.text}>
          Visionary Vogues is where the chronicles of business evolution unfold.
          We are a leading online magazine dedicated to celebrating the
          visionaries, disruptors, and transformative leaders who reshape entire
          industries.
        </Text>
        <br />
        <Text className={styles.text}>
          Our mission is to deliver a unique blend of in-depth storytelling,
          insightful analysis, and actionable advice. We illuminate the
          strategies, innovations, and mindsets that drive groundbreaking
          success, empowering readers to unlock their transformative potential.
        </Text>
      </div>
      <div className={styles.imageStyle}>
        <div className={styles.textStyle}>
          <Title
            style={{
              color: "white",
              textAlign: "left",
              marginLeft: "40px",
              marginTop: "40px",
              fontSize: "1.8em",
              marginBottom: "0",
            }}
            className={styles.abttitle}
          >
            Beyond Success Stories: The Drivers of Change
          </Title>
          <div
            style={{ width: "60%", marginLeft: "auto", marginRight: "8rem" }}
          >
            <Text
              className={styles.beyondtext}
              style={{ fontWeight: "500", fontSize: "22px" }}
            >
              At Visionary Vogues, we go deeper than simply reporting on
              achievements. We delve into the heart of business transformation,
              exploring:
            </Text>
            <ul style={{ fontWeight: "500", fontSize: "22px" }}>
              <li className={styles.li}>
                Pioneering Ideas: Visionary Vogues serves as a hub for
                innovative concepts and approaches that challenge conventional
                paradigms and ignite industry-wide shifts
              </li>
              <li className={styles.li}>
                Strategic Insights: We dissect winning playbooks and reveal the
                strategies that have propelled businesses to unprecedented
                success within their markets
              </li>
              <li className={styles.li}>
                Inspiring Leadership: We profile the brilliant minds behind
                iconic brands, shedding light on their leadership philosophies,
                decision-making processes, and the pivotal moments that shaped
                their trajectories
              </li>
            </ul>
            <Text
              className={styles.beyondtext}
              style={{ fontWeight: "500", fontSize: "22px" }}
            >
              Lessons from Challenges: The path to transformation rarely runs in
              a straight line. We examine both the triumphs and setbacks of
              trailblazers, turning their hard-earned lessons into wisdom for
              our readers.
            </Text>
          </div>
        </div>
      </div>
      <Row
        gutter={[16, 16]}
        justify={"center"}
        style={{ alignItems: "center", marginBottom: "40px" }}
      >
        <Col xs={20} sm={20} md={16} lg={14} xl={13}>
          <Title
            style={{
              marginLeft: 0,
              textAlign: "left",
              marginRight: 0,
              fontWeight: "bolder",
            }}
          >
            Who We Are
          </Title>
          <Text className={styles.whowearetext}>
            The team behind Visionary Vogues consists of astute journalists,
            seasoned business analysts, and skilled storytellers who share a
            common passion: to dissect the DNA of successful business models and
            inspire a new generation of leaders.
          </Text>
          <br />
          <br />
          <Text className={styles.whowearetext}>
            Our editorial team is committed to meticulous research and
            journalistic integrity. Every story is carefully vetted and analyzed
            to ensure that you receive the most accurate, insightful, and
            comprehensive information available. We are dedicated to providing
            you with content that is not only informative but also engaging and
            thought provoking.
          </Text>
        </Col>
        <Col xs={0} sm={0} md={6} lg={8} xl={7}>
          <Image src={whoweare} preview={false} height={"100%"} />
        </Col>
      </Row>
      <div className={styles.participation}>
        <Title
          style={{
            fontSize: "2.2em",
            color: "#fff",
            marginLeft: "30px",
            textAlign: "left",
            fontWeight: "800",
          }}
        >
          We Value Your Participation
        </Title>
      </div>
      <div style={{ background: "#d9e5ff", paddingBottom: "30px" }}>
        <Text className={[styles.participationtext]}>
          Visionary Vogues is more than just a platform for sharing stories;
          it’s a thriving community where aspiring entrepreneurs, established
          business leaders, and thought leaders gather to share insights, engage
          in meaningful discussions, and collaborate on groundbreaking ideas. We
          believe that the power of transformation lies not only in individual
          journeys but also in the collective wisdom and collaborative spirit of
          a unified community.
          <br />
          Here’s how you can become an active member of this vibrant community:
        </Text>
        <ul style={{ fontWeight: 400, padding: "0 6rem" }}>
          <li className={styles.participationli}>
            Embrace the Conversation: Share your perspectives, engage in
            thought-provoking discussions, and collaborate with other visionary
            individuals within our comments sections and on our social media
            platforms.
          </li>
          <li className={styles.participationli}>
            Become an Insider: Subscribe to our newsletter and receive curated
            content, exclusive interviews, and early access to our latest
            features. Be the first to stay ahead of the curve and gain access to
            valuable insights reserved for our dedicated community members.
          </li>
          <li className={styles.participationli}>
            Share Your Story: If you or your company have embarked on a
            transformative journey that has redefined your industry or yourself,
            we want to hear about it! Submit your story and inspire others with
            your experiences and insights.
          </li>
        </ul>
      </div>
      <Row gutter={[32, 16]} justify={"center"} style={{ marginTop: "3rem" }}>
        <Col
          xs={20}
          sm={20}
          md={12}
          lg={12}
          xl={12}
          style={{
            background: "#b4cbff",
            paddingLeft: isMobile ? "20px" : "60px",
          }}
        >
          <Title
            style={{
              marginLeft: 0,
              textAlign: "left",
              marginRight: 0,
              width: "100%",
            }}
          >
            Transformative Content at Your Fingertips
          </Title>
          <Text className={styles.participationli}>
            Visionary Vogues is designed to be a constant source of inspiration
            and actionable intelligence. Whether you are a seasoned entrepreneur
            navigating a rapidly changing landscape or a bright-eyed innovator
            looking to launch your next venture, we provide the tools and
            insights you need to:
          </Text>
          <ul>
            <li className={styles.participationli}>
              Stay Ahead of the Curve: Get informed updates on emerging
              technologies, shifting market trends, and evolving consumer
              demands. Be prepared to anticipate and adapt to the changes that
              will shape the future of business.
            </li>
            <li className={styles.participationli}>
              Learn from the Masters: Gain invaluable lessons from the playbooks
              of industry leaders and iconic brands. Learn from their successes
              and failures to refine your own strategies and avoid costly
              mistakes.
            </li>
          </ul>
          <Image src={transformative2} preview={false} />
        </Col>
        <Col
          xs={20}
          sm={20}
          md={12}
          lg={12}
          xl={12}
          style={{
            background: "#d9e5ff",
            // paddingLeft: "60px",
          }}
        >
          <Image
            src={transformative}
            preview={false}
            style={{ marginTop: "10%" }}
          />
          <ul>
            <li className={styles.participationli}>
              Unlock Your Growth Potential: Discover innovative business models,
              explore the potential of strategic partnerships, and learn how to
              enter new markets with confidence. Identify opportunities for
              scaling your business and achieving sustainable growth.
            </li>
            <li className={styles.participationli}>
              Make Data-Driven Decisions: Empower yourself with insightful
              analysis and curated data that informs your strategic planning and
              decision-making processes. Make informed choices that propel your
              business.
            </li>
          </ul>
          <Text className={styles.participationli}>
            Let Visionary Vogues be your guide on the path to greatness. Embrace
            the power of transformation and unleash your inner visionary.
          </Text>
        </Col>
      </Row>
    </div>
  );
};

export { AboutUs };
