import React, { useEffect } from "react";
import { Col, Image, Layout, Row, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { magazineAction } from "./state/actions";
import { Helmet } from "react-helmet-async";
import Spinner from "../Spinner/Spinner";
import { ErrorHandling } from "../ErrorHandling/ErrorHandling";
import styles from "./Styles/magazineDescription.module.css";

const { Content } = Layout;
const { Title } = Typography;

const MagazineDescription = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const { magazinePDF } = useSelector((state) => state.magazineReducer);
  const { isLoading, message } = useSelector((state) => state.loadingReducer);

  useEffect(() => {
    dispatch(magazineAction.getMagazineByIdModalData(params.id, "user"));
  }, [dispatch, params.id]);

  if (isLoading) {
    return <Spinner />;
  }
  if (typeof message === "object") {
    return (
      <ErrorHandling code={message?.status} statusText={message?.statusText} />
    );
  }

  let getConten = (data) => {
    if (data?.description) {
      const doc = new DOMParser().parseFromString(
        data.description,
        "text/html"
      );

      const divs = doc.querySelectorAll("div");
      let extractedTexts = [];

      for (let i = 0; i < divs.length; i++) {
        const hasImg = divs[i].querySelector("img");
        if (!hasImg) {
          extractedTexts.push(divs[i].textContent.trim());
        }
      }
      let combinedText = extractedTexts.slice(0, 3).join(" ");
      return combinedText;
    }
    return "Thanks";
  };

  return (
    <Content className={styles.content}>
      <Helmet>
        <meta name="keywords" content={magazinePDF?.keywords}></meta>
        <title>{magazinePDF?.title}</title>
        <meta name="description" content={getConten(magazinePDF)} />

        <meta name="twitter:card" content={"type"} />
        <meta name="twitter:title" content={magazinePDF?.title} />
        <meta name="twitter:description" content={getConten(magazinePDF)} />

        <meta property="og:image" content={magazinePDF?.thumbnail} />
        <meta property="og:description" content={getConten(magazinePDF)} />
        <meta property="og:title" content={magazinePDF?.title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:URL"
          content={`https://visionaryvogues.com/magazine/${magazinePDF?._id}`}
        />

        {/* <link
          rel="canonical"
          href={`https://visionaryvogues.com/magazine/${magazinePDF._id}`}
        /> */}
      </Helmet>
      <Row gutter={[16, 16]} className={styles.rowContent}>
        <Col xs={2} sm={2} md={2} lg={3} xl={3} xxl={3}></Col>
        <Col xs={24} sm={24} md={10} lg={9} xl={9} xxl={9}>
          <div className={styles.posterContainer}>
            <Image
              preview={false}
              src={magazinePDF?.thumbnail}
              width={"100%"}
              height={"auto"}
              className={styles.poster}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={10} lg={9} xl={9} xxl={9}>
          <div className={styles.textWrapper}>
            <Title
              style={{
                display: "block",
                marginLeft: "15px",
                marginRight: "0",
                marginBottom: "3px",
              }}
              className={styles.authorName}
            >
              {magazinePDF?.authorName}
            </Title>
            <p
              style={{
                color: "#fff",
                marginLeft: "13px",
                textAlign: "left",
                alignSelf: "flex-start",
                marginTop: "5px",
              }}
              className={styles.title}
            >
              {magazinePDF?.title}
            </p>
            <Link
              to={`/${magazinePDF?.redirectURL}`}
              style={{
                marginLeft: "1px",
                alignSelf: "flex-start",
                marginTop: "0",
              }}
            >
              <div className={styles.buttonWrapper}>
                <button type="submit">Read Digital Version</button>
              </div>
            </Link>
          </div>
        </Col>
        <Col xs={2} sm={2} md={2} lg={3} xl={3} xxl={3}></Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={3}></Col>
        <Col span={18}>
          <div
            className={styles.descImg}
            dangerouslySetInnerHTML={{ __html: magazinePDF?.description }}
          />
        </Col>
        <Col span={3}></Col>
      </Row>
    </Content>
  );
};

export default MagazineDescription;
