import React from "react";
import { v4 as uuidv4 } from "uuid";
import HorizontalCard from "./HorizontalCard";
import { useSelector } from "react-redux";
import Spinner from "../../Spinner/Spinner";
const SubscribeSection = ({ newEntry, prop }) => {
  const { isDailyscoopLoading } = useSelector((state) => state.loadingReducer);

  return isDailyscoopLoading ? (
    <Spinner />
  ) : (
    <>
      {newEntry?.map((data) => (
        <HorizontalCard
          key={uuidv4()}
          title={
            data?.tag ? data?.tag[0].toUpperCase() + data?.tag?.slice(1) : ""
          }
          image={data?.thumbnail}
          description={data?.title}
          border={true}
          orderType={2}
          textType={3}
          goto={prop === "uptrending" ? "goto" : ""}
          subscribeStyle={"subscribeStyle"}
          id={data?.redirectURL}
        />
      ))}
    </>
  );
};

export { SubscribeSection };
